<!-- Modal -->
<div class="modal fade" id="modal-salidas-editar" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Actualizar</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <form [formGroup]="form" (ngSubmit)="submit()">
                <fieldset>
                    <div class="form-group">
                        <label>Estado de la Salida: </label>
                        <select class="form-control" formControlName="status">
                            <option value="pendiente">Pendiente</option>
                            <option value="entregado">Entregado</option>
                        </select>
                    </div>
                </fieldset>
            </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="submit()">Continuar</button>
        </div>
      </div>
    </div>
  </div>