<!-- Modal -->
<div class="modal fade" id="modal-medida-eliminar" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Eliminar Medida</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="medida && medida.id">
                <p align="center">
                    ¿Estás seguro que deseas eliminar la medida <span class="text-primary">{{medida.nombre}}</span>?<br />
                    Este cambio es permanente y todos los productos asignados a esta medida tomarán los valores iniciales.
                </p>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" (click)="submit()">Terminar</button>
        </div>
      </div>
    </div>
  </div>