
<div style="height: 45px;"></div>

<main class="container">
    <div class="row">
        <div class="col-lg-12">
            <h1 class="display-4">Salidas</h1>
        </div>
    </div>

    <div style="height: 25px;"></div>

    <!--<div class="row">
        <div class="col-2 offset-10">
            <button type="button" class="btn btn-outline-primary btn-block" data-toggle="modal" data-target="#modal-cliente-crear">Nuevo</button>
        </div>
    </div>-->
    
    <div style="height: 25px;"></div>
    <section>

        <div *ngIf="!salidas.length">
            <p align="center">No hay salidas aún.</p>
        </div>

        <div class="row" *ngIf="salidas && salidas.length">
            <div class="col-lg-12">
                <table class="table table-hover dataTables">
                    <thead>
                        <tr>
                            <th>Orden</th>                            
                            <th>Fecha de Entrada</th>
                            <th>Cliente</th>
                            <th>Imagen</th>
                            <th>Diseño</th>
                            <th>SKU</th>
                            <th>Lote</th>
                            <th>Piezas</th>
                            <th>Cajas</th>
                            <th>Fecha de Embarque</th>
                            <th>Status</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let salida of salidas" class="over-salida" (click)="showUpdate(salida)" data-toggle="modal" data-target="#modal-salidas-editar">
                            <td>
                                <span class="text-primary">{{ salida.orden_id }}</span>
                            </td>
                            <td>
                                <span *ngIf="salida.orden && salida.orden.id">{{ getFechaEntrega(salida.orden.fecha) | date }}</span>
                            </td>
                            <td>
                                <span *ngIf="salida.cliente && salida.cliente.id"><strong>{{ salida.cliente.empresa | titlecase }}</strong></span>
                            </td>
                            <td>
                                <img *ngIf="salida.producto && salida.producto.id && salida.producto.img" [src]="salida.producto.img" width="36" />
                            </td>
                            <td>
                                <span *ngIf="salida.producto && salida.producto.id">{{ salida.producto.diseno | titlecase }}</span>
                            </td>
                            <td>
                                <span *ngIf="salida.producto && salida.producto.id">{{ salida.producto.sku | uppercase }}</span>
                            </td>
                            <td>
                                <span *ngIf="salida.pallet && salida.pallet.id">{{ salida.pallet.pedido | titlecase }}</span>
                            </td>
                            <td>{{ salida.cantidad }}</td>
                            <td>
                                <span *ngIf="salida.producto && salida.producto.caja">{{ fixCajas(salida.cantidad / salida.producto.caja.piezas) }}</span>
                            </td>
                            <td>
                                <span *ngIf="salida.orden && salida.orden.id">{{ getFechaEntrega(salida.orden.shipping.fecha_entrega) | date }}</span>
                            </td>
                            <td>
                                <span *ngIf="salida.status === 'pendiente'" class="text-danger">{{ salida.status | titlecase }}</span>
                                <span *ngIf="salida.status === 'programada'" class="text-warning">{{ salida.status | titlecase }}</span>
                                <span *ngIf="salida.status === 'entregado'" class="text-success">{{ salida.status | titlecase }}</span>
                            </td>
                            <td></td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>

    </section>
</main>

<app-modal-salidas-editar [salida]="salida" (onUpdate)="onUpdated($event)"></app-modal-salidas-editar>