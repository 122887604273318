import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { NgxNotifierService } from 'ngx-notifier';

declare var $:any;

@Component({
  selector: 'app-modal-medida-eliminar',
  templateUrl: './modal-medida-eliminar.component.html',
  styleUrls: ['./modal-medida-eliminar.component.css']
})
export class ModalMedidaEliminarComponent implements OnInit {
  @Input() medida;
  @Output() onDelete = new EventEmitter<any>();

  constructor( private fb: FirebaseService, private notifier: NgxNotifierService ) { }

  ngOnInit(): void {
  }

  async submit(){
    if ( this.medida  && this.medida.id ){
      await this.fb.deleteMedida(this.medida.id).then( () => {
        this.notifier.createToast("Medida eliminada correctamente", 'success');
        this.onDelete.emit( this.medida.id );
        $('.modal').modal('hide');
      }).catch( err => console.log('Error: ', err));
      
    } else{
      this.notifier.createToast("Falta información", 'error');
    }
  }

}
