import { Injectable } from '@angular/core';
import * as firebase from 'firebase/app';
import 'firebase/storage';
import 'firebase/firestore';

import { v4 } from 'uuid';
import { Cliente } from '../models/cliente.model';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  storage = firebase.storage();
  productos = firebase.firestore().collection('productos');
  stock = firebase.firestore().collection('stock');
  palletes = firebase.firestore().collection('palletes');
  medidas = firebase.firestore().collection('medidas');
  clientes = firebase.firestore().collection('clientes');
  ordenes = firebase.firestore().collection('ordenes');
  salidas = firebase.firestore().collection('salidas');

  constructor() { }

  /** Productos */
  getProductos() {
    return new Promise((resolve, revoke) => {
      this.productos.get().then( docs => {
        //resolve( docs.docs.map( d => { let prod = d.data(); prod.id = d.id; return {...d.data(), id: d.id}; } ) );
        resolve( docs.docs.map( d => { return {min_cajas: 0, ...d.data(), id: d.id}; } ) );
      }, () => {
        revoke([]);
      }).catch(() => {
        revoke([]);
      });
    });
  }

  getFilteredProducts( keyword: string ) {
    return new Promise((resolve, revoke) => {
      if(!keyword) {
        this.ordenes.orderBy('diseno').startAt(keyword).endAt(keyword).get().then(docs => {
          const clientes = docs.docs.map(c => {
            return { ...c.data(), id: c.id };
          });
          resolve(clientes);
        }).catch(() => revoke([]));
      }
    });
  }

  postProducto(data) {
    const id = v4();
    return new Promise((resolve, revoke) => {
      this.productos.doc(id).set(data).then( () => {
        let producto = data;
        producto.id = id;
        resolve( producto );
      }).catch(() => {
        revoke([]);
      });
    });
  }

  putProducto(id, data) {
    return new Promise((resolve, revoke) => {
      this.productos.doc(id).update(data).then( () => {
        let producto = data;
        producto.id = id;
        resolve( producto );
      }).catch(() => {
        revoke([]);
      });
    });
  }

  deleteProducto(id) {
    return new Promise((resolve, revoke) => {
      this.productos.doc(id).delete().then( () => {
        const producto = {id: id};
        resolve( producto );
      }).catch(() => {
        revoke([]);
      });
    });
  }

  /** Palettes */
  validatePallet(pedido: string){
    return new Promise((resolve, revoke) => {
      this.palletes.where('pedido', '==', pedido).get().then( doc => {
        const existe = doc.docs.find(p => p.data().pedido === pedido);
        if( existe ){
          resolve(true);
        } else{
          revoke(false);
        }
      }).catch(e => revoke(false));
    });
  }

  getProductoPallete(pid:string) {
    return new Promise((resolve, revoke) => {
      this.productos.doc(pid).get().then( doc => {
        if(doc.exists){
          resolve( {...doc.data(), id: doc.id} );
        } else{
          resolve( null );
        }
        
      }).catch(() => {
        revoke(null);
      });
    });
  }

  async getPalletes(pid?: string) {
    return await new Promise((resolve, revoke) => {
      if(pid) {
        this.palletes.where('pid', '==', pid).orderBy('pedido', 'asc').get().then( docs => {
          //resolve( docs.docs.map( d => { let pal = d.data(); pal.id = d.id; return pal; } ) );
          resolve( docs.docs.map( d => { return {...d.data(), id: d.id}; } ) );
        }).catch(e => {
          revoke([]);
        });
      } else{
        this.palletes.orderBy('pedido', 'asc').get().then( docs => {
          //resolve( docs.docs.map( d => { let pal = d.data(); pal.id = d.id; return pal; } ) );
          resolve( docs.docs.map( d => { return {...d.data(), id: d.id}; } ) );
        }).catch(e => {
          revoke([]);
        });
      }
      
    });
  }

  getPallet(pallet_id: string) {
    return new Promise((resolve, revoke) => {
      if(pallet_id) {
        this.palletes.doc(pallet_id).get().then( doc => {
          resolve({ ...doc.data(), id: doc.id });
        }, () => {
          revoke({});
        }).catch(() => {
          revoke({});
        });
      } 
    });
  }

  postPallete(data) {
    const id = v4();
    return new Promise((resolve, revoke) => {
      this.palletes.doc(id).set(data).then( () => {
        let pallete = data;
        pallete.id = id;
        resolve( pallete );
      }).catch(() => {
        revoke([]);
      });
    });
  }

  putPallete(id, data) {
    return new Promise((resolve, revoke) => {
      this.palletes.doc(id).update(data).then( () => {
        let pallete = data;
        pallete.id = id;
        resolve( pallete );
      }).catch(() => {
        revoke([]);
      });
    });
  }

  deletePallete(id) {
    return new Promise((resolve, revoke) => {
      this.palletes.doc(id).delete().then( () => {
        const pallete = {id: id};
        resolve( pallete );
      }).catch(() => {
        revoke([]);
      });
    });
  }

  /** Medidas */
  getMedidas() {
    return new Promise((resolve, revoke) => {
      this.medidas.get().then( snap => {
        const medidas = snap.docs.map(m => { let data = m.data(); data.id = m.id; return data; });
        resolve( medidas );
      }).catch(() => {
        revoke([]);
      });
    });
  }

  postMedida(data) {
    const id = v4();
    return new Promise((resolve, revoke) => {
      this.medidas.doc(id).set(data).then( () => {
        let medida = data;
        medida.id = id;
        resolve( medida );
      }).catch(() => {
        revoke([]);
      });
    });
  }

  putMedida(id, data) {
    return new Promise((resolve, revoke) => {
      this.medidas.doc(id).update(data).then( () => {
        let medida = data;
        medida.id = id;
        resolve( medida );
      }).catch(() => {
        revoke([]);
      });
    });
  }

  deleteMedida(id) {
    return new Promise((resolve, revoke) => {
      this.medidas.doc(id).delete().then( () => {
        const medida = {id: id};
        resolve( medida );
      }).catch(() => {
        revoke([]);
      });
    });
  }

  uploadImage(name, path, dataURL){
    var storage = this.storage.ref();
    var uploadTask = storage.child(path + '/' + name).putString(dataURL, "data_url");
    return new Promise( (resolve, revoke ) => {
      uploadTask.on('state_changed', function(snapshot){
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        
        /*switch (snapshot.state) {
          case this.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case this.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
        }*/
      }, function(error) {
        // Handle unsuccessful uploads
        revoke({error: error});
      }, function() {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          resolve({path: downloadURL});
          console.log('File available at', downloadURL);
        });
      });
    });
  }

  /**
   * 
   * @param id 
   * CLIENTES
   */
  getClientes(id?:string) {
    return new Promise((resolve, revoke) => {
      if(!id) {
        this.clientes.get().then(docs => {
          const clientes = docs.docs.map(c => {
            return { ...c.data(), id: c.id };
          });
          resolve(clientes);
        }).catch(() => revoke([]));
      } else{
        this.clientes.doc(id).get().then(doc => {
          resolve({...doc.data(), id: doc.id});
        }).catch(() => revoke([]));
      }
    });
  }

  postCliente(cliente: Cliente){
    const id = v4();
    const fecha = new Date();
    let nuevoCliente = { ...cliente, status: "activo", alta: fecha.toLocaleDateString() };
    nuevoCliente.rfc = nuevoCliente.rfc.toUpperCase();
    nuevoCliente.empresa = nuevoCliente.empresa.toUpperCase();

    return new Promise((resolve, revoke) => {
      this.clientes.doc(id).set(nuevoCliente).then(() => {
        resolve({...cliente, id});
      }).catch(() => revoke(null));
    });
  }

  putCliente(id:string, cliente: Cliente){
    const fecha = new Date();
    let nuevoCliente = { ...cliente, update: fecha.toLocaleDateString() };
    nuevoCliente.rfc = nuevoCliente.rfc.toUpperCase();
    nuevoCliente.empresa = nuevoCliente.empresa.toUpperCase();

    return new Promise((resolve, revoke) => {
      this.clientes.doc(id).update(nuevoCliente).then(() => {
        resolve({...cliente, id});
      }).catch(() => revoke(null));
    });
  }

  deleteCliente(id:string){
    return new Promise((resolve, revoke) => {
      this.clientes.doc(id).delete().then(() => {
        resolve(true);
      }).catch(() => revoke(false));
    });
  }

  /**
   * 
   * ORDENES
   */
  async getOrdenes(cliente_id?:string, orden_id?:string, currency?: string) {
    return await new Promise((resolve, revoke) => {
      if(!cliente_id  && !orden_id) {
        this.ordenes.get().then(docs => {
          if( currency ) {
            const docs2 = docs.docs.map(o => {
              //console.log('subs;:', o.id.substr(0, 2));
              if( currency === 'MX' && currency === o.id.substr(0, 2) ) {
                //console.log('Si es MX');
                return { ...o.data(), id: o.id };
              } else if(currency !== 'MX'){
                //console.log('NO es MX');
                return "MX" !== o.id.substr(0, 2) ? { ...o.data(), id: o.id } : null;
              }
            });

            let newOrders = [];
            for(const o of docs2) {
              if(o){
                newOrders.push(o);
              }
            }
            resolve(newOrders);
            return;
          }

          resolve(docs.docs.map(o => {
            return { ...o.data(), id: o.id };        
          })
        );
          
        }).catch(() => revoke([]));
      } else if( !cliente_id && orden_id !== null) {
        this.ordenes.doc( orden_id ).get().then(doc => {
          resolve({ ...doc.data(), id: doc.id });          
        }).catch(() => revoke([]));
      } else{
        this.ordenes.where('cliente_id', "==", cliente_id).get().then(docs => {
          resolve(docs.docs.map(o => {
              /*this.getSalidas( o.id ).then( response => {
                return { ...o.data(), id: o.id, salidas: response }
              }).catch(() => {
                return { ...o.data(), id: o.id, salidas: [] }
              });*/
              return { ...o.data(), id: o.id }
              
            })
          );
        }).catch(() => revoke([]));
      }
    });
  }

  postOrden(orden) {
    return new Promise((resolve, revoke) => {
      this.ordenes.doc(orden.id).set(orden).then(() => {
        resolve(orden.id);
      }).catch(() => revoke([]));
    });
  }

  async deleteOrden(orden_id: string) {
    return await new Promise((resolve, revoke) => {
      this.ordenes.doc(orden_id).delete().then(() => {
        resolve(true);
      }).catch(() => revoke(false));
    });
  }

  /**
   * SALIDAS
   */
  postSalida(salida) {
    const id = v4();
    return new Promise((resolve, revoke) => {
      this.salidas.doc(id).set(salida).then(() => {
        resolve(id);
      }).catch(() => revoke([]));
    });
  }

  async putSalida(id: string, data: any) {
    return await new Promise((resolve, revoke) => {
      this.salidas.doc(id).update(data).then(() => {
        resolve(true);
      }).catch(() => revoke(false));
    });
  }

  async getSalidas(id?:any) {
    return await new Promise((resolve, revoke) => {
      if(!id) {
        this.salidas.orderBy('orden_id', 'desc').get().then(docs => {
          const salidas = docs.docs.map(c => {
            return { ...c.data(), id: c.id };
          });
          resolve(salidas);
        }).catch(() => revoke([]));
      } else{
        this.salidas.where('orden_id', '==', id).orderBy('cantidad', 'desc').get().then(docs => {          
          resolve(docs.docs.map(c => {
              return { ...c.data(), id: c.id };
            })
          );
        }).catch(e => {
          console.log("Nel", e);
          revoke([])
        });
      }
    });
  }

  getPalletsSold() {
    return new Promise((resolve, revoke) => {
      
        this.salidas.where('sold_env', ">", "0").get().then(docs => {
          const salidas = docs.docs.map(c => {
            return { ...c.data(), id: c.id };
          });
          resolve(salidas);
        }).catch(() => revoke([]));
      }

    );
  }
}
