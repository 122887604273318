import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { FormGroup } from '@angular/forms';
import { NgxNotifierService } from 'ngx-notifier';

@Component({
  selector: 'app-modal-crear-producto',
  templateUrl: './modal-crear-producto.component.html',
  styleUrls: ['./modal-crear-producto.component.css']
})
export class ModalCrearProductoComponent implements OnInit {
  @Output() onCreate = new EventEmitter<any>();
  form: FormGroup;

  constructor( private ws: UserService, private notifier: NgxNotifierService ) { }

  ngOnInit(): void {

  }

  submit(){
    if ( this.form.valid ){
      this.notifier.createToast("Nuevo producto agregado correctamente", 'success');
      const producto = this.ws.crearProducto(this.form.value);
      this.onCreate.emit( producto );
      this.form.reset();
    } else{
      this.notifier.createToast("Falta información", 'error');
    }
  }

}
