import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
declare var XLSX:any;
@Component({
  selector: 'app-packing-list',
  templateUrl: './packing-list.component.html',
  styleUrls: ['./packing-list.component.css']
})
export class PackingListComponent implements OnInit {
  fechaFiltro: string;
  lista_fechas: any = [];
  packingList = [];
  ordenes: any = [];
  clientes: any = [];
  salidas: any = [];

  constructor( private fb: FirebaseService ) { }

  async ngOnInit() {
    const ordenes = await this.fb.getOrdenes();
    this.clientes = await this.fb.getClientes();
    this.salidas = await this.fb.getSalidas();

    this.ordenes = ordenes;
    this.getShippingDates(this.ordenes);
  }

  getShippingDates(ordenes) {
    if(ordenes) {
      const _clientes = this.clientes.map(cliente => {return cliente.id});
      /// console.log('Clientes:', _clientes);

      let fechas = ordenes.filter(orden => _clientes.indexOf(orden.cliente_id) !== -1 ).map(orden => { return orden.shipping.fecha_entrega } );
      /// console.log('Fechas:', fechas, ordenes.map(orden => {return orden.cliente_id} ));
      let fechasFiltradas = [];
      fechas.sort().reverse().map( fecha => {
        let dateOrderFormat = fecha && fecha.split('/').length > 1 ? fecha.split('/').reverse().join('-') : fecha;

        if(fechasFiltradas.indexOf(dateOrderFormat) === -1) {
            fechasFiltradas.push(dateOrderFormat);
        }
        return fecha;
      });

      this.lista_fechas = fechasFiltradas.slice();
      //console.log('Filtradas', this.lista_fechas);
    }
  }

  async obtenerOrdenesFiltradas() {
    //console.log('Filtro', this.fechaFiltro);
    if( this.fechaFiltro ){
      this.packingList = this.ordenes.filter(orden => (orden.shipping.fecha_entrega && orden.shipping.fecha_entrega.split('/').length > 1 ? orden.shipping.fecha_entrega.split('/').reverse().join('-') : orden.shipping.fecha_entrega) === this.fechaFiltro );
    }

    //Obtener salidas de las ordenes filtradas
    this.packingList.map( orden => {
      orden.salidas = this.salidas.filter(salida => salida.orden_id === orden.id);
      return orden;
    });

    console.log('packingList', this.packingList);
  }

  getCliente(cliente_id: string) {
    const cliente: any = this.clientes.filter( cliente => cliente.id === cliente_id)[0];
    return cliente && cliente.nombre ? cliente.nombre : '';
  }

  imprimir() {
    window.print();
  }

}
