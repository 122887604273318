import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-modal-salidas-editar',
  templateUrl: './modal-salidas-editar.component.html',
  styleUrls: ['./modal-salidas-editar.component.css']
})
export class ModalSalidasEditarComponent implements OnInit, OnChanges {
  @Input() salida: any;
  @Output() onUpdate = new EventEmitter<boolean>();
  form: FormGroup;

  constructor( private fb: FirebaseService ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm(){
    this.form = new FormGroup({
      status: new FormControl("", Validators.required)
    });
  }

  ngOnChanges() {
    if( this.salida && this.salida.id ) {
      this.form.patchValue({
        status: this.salida.status
      })
    }
  }

  submit() {
    if( this.form.valid ) {
      const result: any = this.fb.putSalida(this.salida.id, this.form.value);
      this.onUpdate.emit({...this.salida, status: this.form.value.status});
      $('#modal-salidas-editar').hide();
      $('.modal-backdrop').remove();
    }
  }

}
