<main>


<div style="height: 100px;"></div>

<div class="container">
    <h1 class="display-4" align="center">Mosaicos de Diseño Stevens Stock</h1>
    <div style="height: 45px;"></div>
    <div class="row">
        <div class="col-lg-4 offset-lg-4">
            <div class="card">
                <div class="card-body">
                    <div *ngIf="tag == 'signin'">
                    
                        <form [formGroup]="form" (ngSubmit)="submit()">
                            <fieldset>
                                <!--<div class="form-group">
                                    <label>Nombre: *</label>
                                    <input type="text" formControlName="nombre" class="form-control" placeholder="Nombre" />
                                </div>-->
                                <div class="form-group">
                                    <label>Email: *</label>
                                    <input type="email" formControlName="email" class="form-control" placeholder="Email" />
                                </div>
                                <div class="form-group">
                                    <label>Password: *</label>
                                    <input type="password" formControlName="password" class="form-control" placeholder="Password" />
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-block btn-primary">Entrar</button>
                                </div>
                            </fieldset>
                        </form>

                        <p align="center">
                            <a href="#" data-toggle="modal" (click)="changeTo('signup')">¿No tienes usuario aún?</a>
                        </p>

                    </div>

                    <div *ngIf="tag == 'signup'">
                        <form [formGroup]="formRegistro" (ngSubmit)="registrar()">
                            <fieldset>
                                <div class="form-group">
                                    <label>Nombre: *</label>
                                    <input type="text" formControlName="nombre" class="form-control" placeholder="Nombre" />
                                </div>
                                <div class="form-group">
                                    <label>Email: *</label>
                                    <input type="email" formControlName="email" class="form-control" placeholder="Email" />
                                </div>
                                <div class="form-group">
                                    <label>Password: *</label>
                                    <input type="password" formControlName="password" class="form-control" placeholder="Password" />
                                </div>
                                <div class="form-group">
                                    <label>Rol: *</label>
                                    <select formControlName="rol" class="form-control">
                                        <option value="">-</option>
                                        <option value="admin">Admin</option>
                                        <option value="editor">Editor</option>
                                        <option value="analista">Analista</option>
                                    </select>
                                </div>
                                <div class="form-group">
                                    <button type="submit" class="btn btn-block btn-primary">Entrar</button>
                                </div>
                            </fieldset>
                        </form>

                        <p align="center">
                            <a href="#" data-toggle="modal" (click)="changeTo('signin')">Ya tengo un usuario</a>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>

    <div style="height: 200px;"></div>
</div>

</main>