<!-- Modal -->
<div class="modal fade" id="modal-producto-consulta" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-info" id="staticBackdropLabel" *ngIf="pallete && pallete.producto">{{pallete.producto.diseno}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="pallete && pallete.producto">
                <div class="row" *ngIf="pallete.producto.img">
                    <div class="col-6 offset-3">
                        <img [src]="pallete.producto.img" class="img-fluid" />
                    </div>
                </div>

                <div style="height: 25px;"></div>
                <p class="text-secondary">{{pallete.producto.descripcion}}</p>
                <div style="height: 25px;"></div>

                <ul class="list-group">
                    <li class="list-group-item"><strong>SKU: </strong>{{pallete.producto.sku}}</li>
                    <li class="list-group-item"><strong>Medidas: </strong>{{pallete.producto.medidas}}</li>
                    <li class="list-group-item"><strong>Piezas x Caja: </strong>{{pallete.producto.caja.piezas}}</li>
                </ul>
                
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>