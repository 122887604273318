import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Chart } from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-reportes',
  templateUrl: './reportes.component.html',
  styleUrls: ['./reportes.component.css']
})
export class ReportesComponent implements OnInit {
  ano: Number;
  ordenes = [];
  productos = [];
  designLabels = [];
  clientsLabels = [];
  currency: string = "MX";

  dataClients: any = [];
  dataDesigns: any = [];
  dataAnual = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  dataRecaudacionAnual = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  constructor( private fb: FirebaseService, private spinner: NgxSpinnerService ) { }

  async ngOnInit() {
    const date = new Date();
    this.ano = date.getFullYear();
    await this.actualizarAno();
  }

  async prepararReporte() {
    this.dataAnual = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    this.dataRecaudacionAnual = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
    await this.fb.getOrdenes(null, null, this.currency).then( (ordenes: Array<any>) => {
      this.ordenes = ordenes;
      //console.log('Ordenes', ordenes);

      for( let i = 0; i < 12; i++ ) {
        for(const orden of ordenes) {
          let fecha = orden.fecha && orden.fecha.split('-').length > 0 ? orden.fecha.split('-') : orden.fecha.split('/');
          //console.log('Fecha: ', fecha[1]);
          let ano = orden.fecha.split('-').length > 0 ? fecha[0] : fecha[2];
          //console.log('Fecha: ', fecha[1]);

          if( (fecha[1] - 1) === i && this.ano === ano ) {
            this.dataAnual[i] = this.dataAnual[i] + 1;
            this.dataRecaudacionAnual[i] = this.dataRecaudacionAnual[i] + orden.datos_pago.total;
          }
        }
      }
    }).catch( e => console.log('E:', e));
  }

  async actualizarAno() {
    var ctx_canva: any = document.getElementById('myChart');
    var ctx = ctx_canva.getContext('2d');
    ctx.clearRect(0, 0, ctx_canva.width, ctx_canva.height);
    ctx.beginPath();

    var ctx2_canva: any = document.getElementById('myChart2');
    var ctx2 = ctx2_canva.getContext('2d');
    ctx2.clearRect(0, 0, ctx2_canva.width, ctx2_canva.height);
    ctx2.beginPath();

    var ctx3_canva: any = document.getElementById('myChart3');
    var ctx3 = ctx3_canva.getContext('2d');
    ctx3.clearRect(0, 0, ctx3_canva.width, ctx3_canva.height);
    ctx3.beginPath();

    var ctx4_canva: any = document.getElementById('myChart4');
    var ctx4 = ctx4_canva.getContext('2d');
    ctx4.clearRect(0, 0, ctx4_canva.width, ctx4_canva.height);
    ctx4.beginPath();

    //console.log('RE', this.dataRecaudacionAnual);
    this.spinner.show();
    await this.prepararReporte();
    await this.calcularReporteDiseno();
    await this.calcularReporteClientes();

    var chart = new Chart(ctx, {
        // The type of chart we want to create
        type: 'bar',

        // The data for our dataset
        data: {
            labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
            datasets: [{
                label: 'Reporte Anual de Ordenes',
                backgroundColor: 'rgb(255, 99, 132)',
                borderColor: 'rgb(255, 99, 132)',
                data: this.dataAnual
            }]
        },

        // Configuration options go here
        options: {}
    });

    var chart2 = new Chart(ctx2, {
      // The type of chart we want to create
      type: 'line',

      // The data for our dataset
      data: {
          labels: ['Ene', 'Feb', 'Mar', 'Abr', 'May', 'Jun', 'Jul', 'Ago', 'Sep', 'Oct', 'Nov', 'Dic'],
          datasets: [{
              label: `Reporte Anual de Ingresos ${this.currency}`,
              backgroundColor: 'rgb(255, 99, 132)',
              borderColor: 'rgb(255, 99, 132)',
              data: this.dataRecaudacionAnual
          }]
      },

      // Configuration options go here
      options: {}
    });

    var chart3 = new Chart(ctx3, {
      // The type of chart we want to create
      type: 'bar',

      // The data for our dataset
      data: {
          labels: this.designLabels,
          datasets: [{
              label: `Diseños`,
              backgroundColor: 'rgb(177, 143, 255)',
              borderColor: 'rgb(177, 143, 255)',
              data: this.dataDesigns
          }/*,
          {
            label: `Mangos`,
            backgroundColor: 'rgb(133, 130, 232)',
            borderColor: 'rgb(133, 130, 232)',
            data: 10
          },
          {
            label: `Manzanas`,
            backgroundColor: 'rgb(156, 179, 255)',
            borderColor: 'rgb(156, 179, 255)',
            data: 1
          },
          {
            label: `Tomates`,
            backgroundColor: 'rgb(130, 181, 232)',
            borderColor: 'rgb(130, 181, 232)',
            data: 2
          },
          {
            label: `Alubias`,
            backgroundColor: 'rgb(143, 228, 255)',
            borderColor: 'rgb(143, 228, 255)',
            data: 2
          }*/]
      },

      // Configuration options go here
      options: {}
    });

    var chart4 = new Chart(ctx4, {
      // The type of chart we want to create
      type: 'bar',

      // The data for our dataset
      data: {
          labels: this.clientsLabels,
          datasets: [{
              label: `Clientes que más compran (limitado a 10)`,
              //backgroundColor: 'rgb(177, 143, 255)',
              //borderColor: 'rgb(177, 143, 255)',
              backgroundColor: 'rgb(156, 179, 255)',
              borderColor: 'rgb(156, 179, 255)',
              data: this.dataClients
          }/*,
          {
            label: `Mangos`,
            backgroundColor: 'rgb(133, 130, 232)',
            borderColor: 'rgb(133, 130, 232)',
            data: 10
          },
          {
            label: `Manzanas`,
            backgroundColor: 'rgb(156, 179, 255)',
            borderColor: 'rgb(156, 179, 255)',
            data: 1
          },
          {
            label: `Tomates`,
            backgroundColor: 'rgb(130, 181, 232)',
            borderColor: 'rgb(130, 181, 232)',
            data: 2
          },
          {
            label: `Alubias`,
            backgroundColor: 'rgb(143, 228, 255)',
            borderColor: 'rgb(143, 228, 255)',
            data: 2
          }*/]
      },

      // Configuration options go here
      options: {}
    });

    this.spinner.hide();
  }

  async calcularReporteDiseno() { //Calcular de las ordenes el diseño más vendido
    this.designLabels = [];
    this.dataDesigns = [];
    if( !this.ordenes.length ) {
      return 0;
    }

    //Obtener Salidas
    let productos = [];
    for( let i = 0; i < this.ordenes.length; i++ ) {
      const orden = this.ordenes[i];
      let fecha = orden.fecha && orden.fecha.split('-').length > 0 ? orden.fecha.split('-') : orden.fecha.split('/');
      //console.log('Fecha: ', fecha[1]);
      let ano = orden.fecha.split('-').length > 0 ? fecha[0] : fecha[2];
      if( ano != this.ano ) {
        continue;
      }

      await this.fb.getSalidas(this.ordenes[i].id).then( (salidas: any) => {
        //console.log('Salidas ', salidas);
        this.ordenes[i].ord_salidas = salidas;
        for(const s of salidas) {
          productos.push({...s.producto, quantity: s.cantidad});
        }
        
      }).catch(e => console.log('No tiene salidas'));
    }

    let orderedProducts = [];
    let quantities = [];
    for( const p of productos ){  //Los almacena temporalmente 1 vez para contabilizarlos
      const index = orderedProducts.indexOf(p.id);
      //console.log('Index', index);
      if( index === -1 ) {
        orderedProducts.push(p.id);
        let quantity = 0;
        productos.map( prod => { 
          if( prod.id === p.id ) {
            // Sumar cantidades de salidas
            quantity = quantity + prod.quantity;
          }
        });

        quantities.push( quantity );
      }
    }
    
    let countedProducts = [];
    for( let i = 0; i < orderedProducts.length; i++ ){  //Los va a contar de uno en uno por id
      let quantity = 0;
      productos.map( p => { 
        if( p.id === orderedProducts[i] ) {
          // Sumar cantidades de salidas
          quantity = quantity + p.quantity;
        }
      });
      const diseno = productos.filter(p => p.id === orderedProducts[i])[0].diseno;
      countedProducts.push({product_id: orderedProducts[i], quantity: quantity, name: diseno });
    }

    /*
      Ordenar design labels
    */
   let otherOrder = [];
   let cont = countedProducts.length;
   while(cont > 0) {
    let max: any = null;
    for(let i = 0; i < countedProducts.length; i++) { //Order 
      if( max && max.product_id ) {
        if(max.quantity < countedProducts[i].quantity) {  //Verifica el mayor
          max = countedProducts[i];
        }
      } else{
        max = countedProducts[i];
      }
    }

    countedProducts = countedProducts.filter( product => product.product_id !== max.product_id );
    otherOrder.push(max);
    cont = cont - 1;
   }
   
    for( let i = 0; i < otherOrder.length; i++ ){  //Los va a contar de uno en uno por id
      this.designLabels.push(otherOrder[i].name);
      this.dataDesigns.push(otherOrder[i].quantity);
    }

    this.designLabels = this.designLabels.slice(0, 5);
    this.dataDesigns = this.dataDesigns.slice(0, 5);
  }

  async calcularReporteClientes() {
    this.clientsLabels = [];
    this.dataClients = [];

    let clientes: any = await this.fb.getClientes();
    clientes = clientes.map( cliente => {
      cliente.ordenes = this.ordenes.filter( orden => orden.cliente_id === cliente.id );

      let ordenesPeriodo = [];
      for(let i = 0; i < cliente.ordenes.length; i++) {
        let fecha = cliente.ordenes[i].fecha && cliente.ordenes[i].fecha.split('-').length > 0 ? cliente.ordenes[i].fecha.split('-') : cliente.ordenes[i].fecha.split('/');
        //console.log('ord: ', fecha[1]);
        let ano = cliente.ordenes[i].fecha.split('-').length > 0 ? fecha[0] : fecha[2];
        if( ano != this.ano ) {
          continue;
        } else{
          ordenesPeriodo.push(cliente.ordenes[i]);
        }
      }

      let inversion = 0;
      cliente.ordenes = ordenesPeriodo.map( ord => {
        inversion = inversion + ord.datos_pago.total;
        return ord;
      });

      cliente.total = inversion;

      return cliente;
    });

    clientes = clientes.filter( cliente => cliente.ordenes.length > 0 );
    //console.log('Clientes', clientes);

    //Ordenar los clientes de quien tiene mayor ordenes creadas.
    let orderedClients = [];
    let cont = clientes.length;
    
    //let poolClients = clientes;
    while(cont > 0) {
      let max: any = null;

      for(let i = 0; i < clientes.length; i++) {
        if( max && max.total ) {
          if( max.total < clientes[i].total ) {
            max = clientes[i];
          }
        } else{
          max = clientes[i];
        }
      }

      orderedClients.push(max);
      clientes = clientes.filter( cliente => cliente.id !== max.id );
      cont = cont - 1;
    }

    console.log('Clientes', orderedClients);

    for(let i = 0; i < orderedClients.length; i++) {
      this.clientsLabels.push( orderedClients[i].empresa );
      this.dataClients.push( orderedClients[i].total );
    }
  }

}
