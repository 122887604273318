<div style="height: 45px;"></div>
<main class="container">
    <div class="row">
        <div class="col-lg-12">
            <h1 class="display-4">Productos</h1>
        </div>
    </div>

    <div style="height: 25px;"></div>

    <div class="row">
        <div class="col-3">
            <button type="button" class="btn btn-outline-info btn-block" [routerLink]="['/app', 'medidas']">Config. Medidas</button>
        </div>
        <div class="col-3">
            <button type="button" class="btn btn-outline-secondary btn-block" (click)="exportToExcel('xlsx')">Exportar</button>
        </div>
        <div class="col-3 offset-3">
            <button type="button" class="btn btn-outline-primary btn-block" data-toggle="modal" data-target="#modal-producto-crear">Nuevo</button>
        </div>
    </div>

    <div style="height: 25px;"></div>
    <section>
        <div *ngIf="!productos.length">
            <p align="center">No hay productos en la lista <a href="#" role="button" data-toggle="modal" data-target="#modal-producto-crear">agregar uno nuevo</a></p>
        </div>

        <div class="row" *ngIf="productos && productos.length">
            <div class="col-lg-12">
                <table id="table_products" class="table table-hover dataTables">
                    <thead>
                        <tr>
                            <th>Imagen</th>
                            <th>Diseño</th>
                            <th>Medidas</th>
                            <th>SKU</th>
                            <th>Inv. Venta</th>
                            <th>Inv. Real</th>
                            <th>CS</th>
                            <th>SF</th>
                            <th>M2</th>
                            <th>P</th>
                            <th>Min/Máx cajas</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let p of productos" (click)="infoDetails(p, p.palletes)">
                            <td>
                                <img [src]="p.img" *ngIf="p.img && p.img != ''" width="42" />
                            </td>
                            <td>
                                <span class="text-info" *ngIf="p.cajas_totales > p.min_cajas">
                                    {{ p.diseno }}
                                </span>
                                <span class="text-danger" *ngIf="p.cajas_totales <= p.min_cajas">
                                    {{ p.diseno }}
                                </span>
                            </td>
                            <td>{{ p.medidas }}</td>
                            <td>{{ p.sku }}</td>
                            <td>{{ p.venta }}</td>
                            <td>
                                <span class="text-warning" *ngIf="p.venta > calculaPiezasReal(p.palletes)">{{ calculaPiezasReal(p.palletes) }}</span>
                                <span *ngIf="p.venta == calculaPiezasReal(p.palletes)">{{ calculaPiezasReal(p.palletes) }}</span>
                            </td>
                            <td>
                                <span class="text-warning" *ngIf="calculaPiezasReal(p.palletes) < p.venta && calculaPiezasReal(p.palletes) > 0">{{ calculaPiezasReal(p.palletes) / p.caja.piezas  | number }}</span>
                                <span class="text-danger" *ngIf="calculaPiezasReal(p.palletes) < 1 && calculaPiezasReal(p.palletes) != p.venta">0</span>
                                <span *ngIf="calculaPiezasReal(p.palletes) == p.venta">{{ p.venta / p.caja.piezas }}</span>                                
                            </td>
                            <td>{{ calculaMedida(p.venta, p.caja.piezas, p.mid, "pies")  | number }}</td>
                            <td>{{ calculaMedida(p.venta, p.caja.piezas, p.mid, "m2")  | number }}</td>
                            <td>
                                <span *ngIf="p.palletes">{{ p.palletes.length  | number }}</span>
                            </td>
                            <td>
                                <span>{{ p.min_cajas | number }}</span> / <span>{{ p.max_cajas | number }}</span>
                            </td>
                            <td>
                                <a class="nav-link dropdown-toggle btn btn-danger btn-sm" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="material-icons">settings</i>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#modal-producto-editar" (click)="select(p)">
                                        <i class="material-icons">edit</i> Editar
                                    </a>
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#modal-producto-eliminar" (click)="select(p)">
                                        <i class="material-icons">delete</i> Eliminar 
                                    </a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#modal-entrada-crear" (click)="select(p)">
                                        <i class="material-icons">add</i> Añadir Pallet
                                    </a>
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#modal-producto-entradas" (click)="select(p)">
                                        <i class="material-icons">view_list</i> Ver Pallets
                                    </a>                                
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row" *ngIf="productos && productos.length" style="visibility: hidden; height: 0; display: none;">
            <div class="col-lg-12">
                <table id="table_products_export" class="table table-hover">
                    <thead>
                        <tr>
                            <th>Diseño</th>
                            <th>Medidas</th>
                            <th>SKU</th>
                            <th>Inv. Venta</th>
                            <th>Inv. Real</th>
                            <th>CS</th>
                            <th>SF</th>
                            <th>M2</th>
                            <th>P</th>
                            <th>Min/Máx cajas</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let p of productos" (click)="infoDetails(p, p.palletes)">
                            <td>
                                <span class="text-info" *ngIf="p.cajas_totales > p.min_cajas">
                                    {{ p.diseno }}
                                </span>
                                <span class="text-danger" *ngIf="p.cajas_totales <= p.min_cajas">
                                    {{ p.diseno }}
                                </span>
                            </td>
                            <td>{{ p.medidas }}</td>
                            <td>{{ p.sku }}</td>
                            <td>{{ p.venta }}</td>
                            <td>
                                <span class="text-warning" *ngIf="p.venta > calculaPiezasReal(p.palletes)">{{ calculaPiezasReal(p.palletes) }}</span>
                                <span *ngIf="p.venta == calculaPiezasReal(p.palletes)">{{ calculaPiezasReal(p.palletes) }}</span>
                            </td>
                            <td>
                                <span class="text-warning" *ngIf="calculaPiezasReal(p.palletes) < p.venta && calculaPiezasReal(p.palletes) > 0">{{ calculaPiezasReal(p.palletes) / p.caja.piezas  | number }}</span>
                                <span class="text-danger" *ngIf="calculaPiezasReal(p.palletes) < 1 && calculaPiezasReal(p.palletes) != p.venta">0</span>
                                <span *ngIf="calculaPiezasReal(p.palletes) == p.venta">{{ p.venta / p.caja.piezas }}</span>                                
                            </td>
                            <td>{{ calculaMedida(p.venta, p.caja.piezas, p.mid, "pies")  | number }}</td>
                            <td>{{ calculaMedida(p.venta, p.caja.piezas, p.mid, "m2")  | number }}</td>
                            <td>
                                <span *ngIf="p.palletes">{{ p.palletes.length  | number }}</span>
                            </td>
                            <td>
                                <span>{{ p.min_cajas | number }}</span> / <span>{{ p.max_cajas | number }}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</main>

<app-modal-producto-crear [medidas]="medidas" (onCreate)="onCreated($event)"></app-modal-producto-crear>
<app-modal-producto-editar [medidas]="medidas" [producto]="producto" (onUpdate)="onUpdated($event)"></app-modal-producto-editar>
<app-modal-producto-eliminar [producto]="producto" (onDelete)="onDeleted($event)"></app-modal-producto-eliminar>
<app-modal-producto-entradas [producto]="producto"></app-modal-producto-entradas>

<app-modal-entrada-crear [producto]="producto" (onCreate)="onCreatedPallete($event)"></app-modal-entrada-crear>
