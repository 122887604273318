<app-header></app-header>
<ngx-spinner>
    <h4 class="text-white"><strong>Cargando...</strong></h4>
</ngx-spinner>
<ngx-notifier 
    [allowDuplicates]="true"
    [allowHTML]="true"
    [className]=""
    [duration]="3000"
    [disableAnimations]="false"
    [dismissOnClick]="false"
    
    [max]="5"
>
</ngx-notifier>
<router-outlet></router-outlet>
<app-footer></app-footer>