import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { v4 as uuidv4 } from 'uuid'; // For version 5
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.css']
})
export class ImageCropperComponent implements OnInit {
  @Input() path: string = "";
  @Output() onUpload = new EventEmitter<string>();
  imageChangedEvent: any = '';
  croppedImage: any = '';
  finalImage = "";
  onCropping = false;
  @Input() initialImage:string = "/src/assets/upload.png";

  constructor( private fb: FirebaseService ) { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    if(!this.initialImage){
      this.initialImage = "/src/assets/upload.png";
    }

    if (!this.path){
      console.log('No path provided');
    } else{
      this.initialImage = "src/assets/upload.png";
    }
  }

  select(){
    document.getElementById('file').click();
  }

  fileChangeEvent(event: any): void {
      this.imageChangedEvent = event;
      this.onCropping = true;
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }
  imageLoaded() {
      // show cropper
  }
  cropperReady() {
      // cropper ready
  }
  loadImageFailed() {
      // show message
  }

  cancelar(){
    this.imageChangedEvent = null;
    this.croppedImage = "";
    this.onCropping = false;
  }

  upload(){
    if ( this.croppedImage ){
      this.fb.uploadImage(uuidv4(), this.path, this.croppedImage).then( (uploadedPath: {path:string}) => {
        //console.log('Uploaded to: ', uploadedPath);
        this.initialImage = uploadedPath.path;
        this.onUpload.emit(uploadedPath.path);
        this.cancelar();
      }).catch(error => console.log('Error: ', error));
    } 
  }


}
