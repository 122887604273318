import { Component, OnInit, Output } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxNotifierService } from 'ngx-notifier';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.css']
})
export class AuthComponent implements OnInit {
  form: FormGroup;
  formRegistro: FormGroup;
  tag:string = 'signin';

  constructor( private auth: AuthService, private router: Router, private notifier: NgxNotifierService ) { }

  ngOnInit(): void {
    this.initForm();
    this.isLogged();
  }

  initForm(){
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(150)]),
      password: new FormControl('', [Validators.minLength(6), Validators.required])
    });

    this.formRegistro = new FormGroup({
      nombre: new FormControl('', [Validators.required, Validators.minLength(6), Validators.maxLength(150)]),
      rol: new FormControl("", Validators.required),
      email: new FormControl('', [Validators.required, Validators.email, Validators.maxLength(150)]),
      status: new FormControl('activo', Validators.required),
      password: new FormControl('', [Validators.minLength(6), Validators.required])
    });
  }

  isLogged() {  //Verifica si ya hay una sessión abierta, redirecciona
    //console.log('Is logged? ', this.auth.isLogged());
    if ( this.auth.isLogged() ) {
      this.router.navigate(['/app']);
    }
  
  }

  submit(){
    if ( this.form.valid ){
      //console.log('push it', this.form.value);
      this.notifier.createToast('Ingresando por favor espera...', 'info', 3000);
      this.auth.signIn(this.form.value).then( (response: any) => {
        this.notifier.createToast('¡Bienvenido ' + response.nombre + '!', 'success', 3000);
        this.router.navigate(['/app']);
      }).catch(err => {
        console.log('Error: ', err);
        if(err && err.message) {
          this.notifier.createToast(this.auth.getError(err), 'danger', 3000)
        } else{
          this.notifier.createToast('Lo sentimos, ha ocurrdo un error', 'danger', 5000);
        }
        
      });
    } else{
      //console.log('push it',  this.form.valid, ' this has ', this.form.value);
      this.notifier.createToast('Para poder continuar debes ingresar los campos obligatorios', 'warning', 3000);
    }
  }

  registrar() {
    if ( this.formRegistro.valid ){
      let vars = this.formRegistro.value;
      let password = vars.password;
      delete vars.password;

      const fecha = new Date();
      vars.alta = fecha.toLocaleDateString();

      this.auth.signUp(vars, password).then( (response: any) => {
        //console.log('Data Signup', response);
        this.notifier.createToast('¡Bienvenido ' + response.nombre + '!', 'success', 3000);
        this.router.navigate(['/app']);
      }).catch(err => {
        //console.log('Error', err);
        this.notifier.createToast(this.auth.getError(err), 'danger', 3000)
      });
    } else{
      //console.log('push it',  this.form.valid, ' this has ', this.form.value);
      this.notifier.createToast('Para poder continuar debes ingresar los campos obligatorios', 'warning', 3000);
    }
  }

  changeTo(tag:string) {
    this.tag = tag;
  }

}
