import { Injectable, Inject } from '@angular/core';
import { StorageService, SESSION_STORAGE } from 'ngx-webstorage-service';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  usuarios = firebase.firestore().collection('usuarios');
  errores: Array<{code:string, message:string}> = [
    {
      code: "auth/user-not-found",
      message: "El usuario ingresado no existe."
    },
    {
      code: "auth/invalid-email",
      message: "El correo electrónico ingresado no es correcto."
    },
    {
      code: "auth/email-already-in-use",
      message: "El correo electrónico ingresado ya está registrado."
    },
    {
      code: "auth/wrong-password", 
      message: "La contraseña es inválida o el usuario no existe."
    }
  ];

  constructor( @Inject(SESSION_STORAGE) private storage: StorageService ) { }

  /**
   * 
   * 
   * $("#yourHtmTable").table2excel({
        exclude: ".excludeThisClass",
        name: "Worksheet Name",
        filename: "SomeFile.xls", // do include extension
        preserveColors: false // set to true if you want background colors and font colors preserved
    });
   */

  signIn( data: { email: string, password: string }) {
    return new Promise((resolve, revoke) => {
      firebase.auth().signInWithEmailAndPassword(data.email, data.password).then( data => {
        this.getUserData(data.user.email).then(callback => {
          resolve(callback);
        }).catch(err => revoke(err));
      }).catch( err => revoke(err));
    });
  }

  signUp( data: { email: string, status: string, rol: string, alta: string, nombre: string }, password: string) {
    return new Promise((resolve, revoke) => {
      firebase.auth().createUserWithEmailAndPassword(data.email, password).then( () => {
        this.postUserData(data.email, data).then(() => {
          resolve(data);
        }).catch(err => revoke(err));
      }).catch( err => revoke(err));
    });
  }

  signOut() {
    this.storage.remove('session');
    this.storage.clear();
    return firebase.auth().signOut();
  }

  setAuth(data){
    return this.storage.set('session', data);
  }

  getAuth() {
    return this.storage.get('session');
  }

  isLogged() {
    return this.getAuth() ? this.getAuth() : 0;
  }

  getUserData( email ){
    return new Promise( (resolve, revoke) => {
      this.usuarios.doc(email).get().then( doc => {
        if (doc.exists) {
          let data = doc.data();
          data.id = doc.id;

          this.setAuth(data);
          resolve(data);
        }
      }).catch(err => revoke(err));
    });
  }

  postUserData(id, data) {
    return new Promise( (resolve, revoke) => {
      this.usuarios.doc(id).set(data).then( () => {
        data.id = id;
        this.setAuth(data);

        resolve(true);
      }).catch(err => revoke(err));
    });
  }

  getError(err: {code:string, message:string}) : string{
    return this.errores.find(e => e.code === err.code).message;
  }
}
