import { Injectable, Inject } from '@angular/core';
import { SESSION_STORAGE, StorageService } from 'ngx-webstorage-service';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  today = new Date();

  constructor(@Inject(SESSION_STORAGE) private storage: StorageService) { }

  /**
   * Productos
   */
  crearProducto(data){
    let productos = this.obtenerProductos();
    data.id = 'p' + this.today.getUTCMilliseconds() + 'k' + this.today.getUTCHours();
    productos.push(data);
    return data;
  }

  obtenerProductos(){
    return this.storage.get('productos');
  }

  setProductos(data){
    this.storage.set('productos', data);
  }

  setDataTables() {
    $(".dataTables").DataTable().destroy();
    setTimeout(() => {
      $(".dataTables").DataTable({
        order: [],
        language: {
          lengthMenu: "Mostrar _MENU_ registros por página",
          zeroRecords: "No hay resultados",
          info: "Mostrando _START_ a _END_ de _TOTAL_ registros",
          infoEmpty: "No hay resultados",
          infoFiltered: "(filtrados de _MAX_ registros totales)",
          processing: "Procesando...",
          search: "Buscar:",
          paginate: {
            first: "Primera",
            last: "Última",
            next: "Siguiente",
            previous: "Anterior"
          },
          aria: {
            sortAscending: ": activar orden ascendente",
            sortDescending: ": activar orden descendente"
          }
        }
      });
    }, 800);
  }
  
}
