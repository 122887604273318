
<div style="height: 45px;"></div>

<main class="container">
    <div class="row">
        <div class="col-lg-12">
            <h1 class="display-4">Clientes</h1>
        </div>
    </div>

    <div style="height: 25px;"></div>

    <div class="row">
        <div class="col-2 offset-10">
            <button type="button" class="btn btn-outline-primary btn-block" data-toggle="modal" data-target="#modal-cliente-crear">Nuevo</button>
        </div>
    </div>
    
    <div style="height: 25px;"></div>
    <section>

        <div *ngIf="!clientes.length">
            <p align="center">No hay clientes en la lista <a href="#" data-toggle="modal" data-target="#modal-cliente-crear">agregar uno nuevo</a></p>
        </div>

        <div class="row" *ngIf="clientes && clientes.length">
            <div class="col-lg-12">
                <table class="table table-hover dataTables">
                    <thead>
                        <tr>
                            <th>Cliente</th>
                            <th>Email</th>
                            <th>Teléfono</th>
                            <th>Empresa</th>
                            <th>Dirección</th>
                            <th>RFC</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let c of clientes">
                            <td>{{c.nombre}}</td>
                            <td>{{c.email}}</td>
                            <td>{{c.telefono}}</td>
                            <td>{{c.empresa}}</td>
                            <td>{{c.direccion}}</td>
                            <td>{{c.rfc}}</td>
                            <td>
                                <a class="nav-link dropdown-toggle btn btn-danger btn-sm" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="material-icons">settings</i>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <!--<a class="dropdown-item" href="#" data-toggle="modal" data-target="#modal-orden-crear" (click)="select(c)">
                                        <i class="material-icons">add</i> Crear Orden
                                    </a>-->
                                    <a class="dropdown-item" [routerLink]="['/app', 'ordenes', c.id]">
                                        <i class="material-icons">view_list</i> Ordenes
                                    </a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#modal-cliente-editar" (click)="select(c)">
                                        <i class="material-icons">edit</i> Editar
                                    </a>
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#modal-cliente-eliminar" (click)="select(c)">
                                        <i class="material-icons">delete</i> Eliminar 
                                    </a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </section>
</main>

<app-modal-cliente-crear (onCreate)="onCreated($event)"></app-modal-cliente-crear>
<app-modal-cliente-editar [cliente]="cliente" (onUpdate)="onUpdated($event)"></app-modal-cliente-editar>
<app-modal-cliente-eliminar [cliente]="cliente" (onDelete)="onDeleted($event)"></app-modal-cliente-eliminar>