import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Producto, Pallete } from 'src/app/models/producto.model';
import { UserService } from 'src/app/services/user.service';
import { NgxNotifierService } from 'ngx-notifier';
import { NgxSpinnerService } from 'ngx-spinner';

declare var XLSX:any;
@Component({
  selector: 'app-productos',
  templateUrl: './productos.component.html',
  styleUrls: ['./productos.component.css']
})
export class ProductosComponent implements OnInit {
  productos: any = [];
  producto: Producto;
  pies = 5.3333;
  m2 = 0.48;
  medidas: any = [];

  tramites = {
    entradas: [],
    salidas: []
  };

  constructor( private fb: FirebaseService, private us: UserService, private notifier: NgxNotifierService, private spinner: NgxSpinnerService ) { }

  async ngOnInit() {
    //this.notifier.createToast("Cargando productos... Por favor espera.", 'info', 5000);
    this.spinner.show();

    this.medidas = await this.fb.getMedidas();

    this.productos = await this.fb.getProductos();
    const pallets: any = await this.fb.getPalletes();

    if( pallets.length ){
      for(let i = 0; i < this.productos.length; i++) {
        this.productos[i].palletes = pallets.filter( (pallet: {pid:string} ) => pallet.pid === this.productos[i].id);
        this.productos[i].cajas_totales = this.calculaPiezasReal(this.productos[i].palletes) / this.productos[i].caja.piezas;
        this.productos[i].min_cajas = this.productos[i].min_cajas ? this.productos[i].min_cajas : 0;
        this.productos[i].max_cajas = this.productos[i].max_cajas ? this.productos[i].max_cajas : 0;
      }
    }
    
    this.us.setDataTables();
    this.spinner.hide();
  }

  select(p) {
    this.producto = p;
  }

  onCreated(ev) {
    if (ev) {
      this.productos.push(ev);
      this.us.setDataTables();
    }
  }

  onUpdated(ev) {
    if (ev) {
      this.productos = this.productos.filter(p => p.id !== ev.id);
      this.productos.push(ev);
      this.us.setDataTables();
    }
  }

  onDeleted(ev) {
    if (ev) {
      this.productos = this.productos.filter(p => p.id !== ev.id);
      this.us.setDataTables();
    }
  }

  onCreatedPallete(ev) {
    if(ev) {
      this.productos = this.productos.filter(p => p.id !== this.producto.id);
      this.producto.palletes = this.producto.palletes ? this.producto.palletes : [];
      this.producto.palletes.push(ev); 
      this.productos.push( this.producto );
      this.us.setDataTables();
    }
  }

  round(data) {
    return Math.round(data);
  }

  calculaPiezasReal( palletes: Pallete[] ){
    let vendidas: any = 0;
    let reales: any = 0;

    if( palletes && palletes.length ) {      
      palletes.map( pallet => {
        const _vendidas: Number = pallet.piezas_vendidas ? Number(pallet.piezas_vendidas) : 0;
        const _real: Number = pallet.piezas ? Number(pallet.piezas) : 0;
        vendidas += _vendidas;
        reales += _real;
        return pallet;
      });
    }

    return reales - vendidas;
  }

  calculaMedida(piezas: Number, cajas: Number, id:string, size: string) {
    if(!id) {
      return 0;
    }

    if( size === 'pies' ) {
      return (Number(piezas) / Number(cajas)) * this.medidas.find( m => m.id === id ).pies;
    } else{
      return (Number(piezas) / Number(cajas)) * this.medidas.find( m => m.id === id ).m2;
    }
  }

  infoDetails(pallets, product) {
    //console.log('Details: ', product, pallets);
  }

  exportToExcel(type, fn?, dl?) {
    var elt = document.getElementById('table_products_export');
    var wb = XLSX.utils.table_to_book(elt, { sheet: "datos" });
    return dl ?
      XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
      XLSX.writeFile(wb, fn || ('Productos.' + (type || 'xlsx')));
  }
}
