import { Component, OnInit } from '@angular/core';
import { NgxNotifierService } from 'ngx-notifier';
import { FirebaseService } from 'src/app/services/firebase.service';
import { UserService } from 'src/app/services/user.service';
import { Cliente } from '../../models/cliente.model';

@Component({
  selector: 'app-clientes',
  templateUrl: './clientes.component.html',
  styleUrls: ['./clientes.component.css']
})
export class ClientesComponent implements OnInit {
  clientes: Cliente[] = [];
  cliente: Cliente;

  constructor( private fb: FirebaseService, private us: UserService, private notifier: NgxNotifierService ) { }

  ngOnInit(): void {
    this.cargarClientes();
  }

  cargarClientes() {
    this.notifier.createToast("Cargando clientes...", "info", 1500);
    this.fb.getClientes().then((clientes: Cliente[]) => {
      this.clientes = clientes;
      this.us.setDataTables();
    }).catch(() => {});
  }

  select(cliente) {
    this.cliente = cliente;
  }

  onCreated(event) {
    if(event) {
      this.clientes.push(event);
      this.us.setDataTables();
    }
  }

  onUpdated(event) {
    if(event) {
      this.clientes = this.clientes.filter(cliente => cliente.id !== event.id );
      this.clientes.push(event);
      this.us.setDataTables();
    }
  }

  onDeleted(event) {
    if(event) {
      this.clientes = this.clientes.filter(cliente => cliente.id !== event );
      this.us.setDataTables();
    }
  }

}
