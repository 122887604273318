<div style="height: 45px;"></div>

<main class="container">
    <div class="row">
        <div class="col-lg-6 offset-lg-3">
            <h1 class="display-4">Packing List</h1>
        </div>
        <div class="col-lg-3">
            <button type="button" class="btn btn-outline-secondary" (click)="imprimir()">Imprimir Página</button>
        </div>
    </div>

    <div style="height: 25px;"></div>

    <section>
        <div class="row">
            <div class="col-lg-6 offset-lg-3">
                <div class="form-group">
                    <label>Selecciona una fecha: </label>
                    <select class="form-control" name="fecha" [(ngModel)]="fechaFiltro" required (change)="obtenerOrdenesFiltradas()">
                        <option value="">Fechas Disponibles</option>
                        <option [value]="fecha" *ngFor="let fecha of lista_fechas">{{ fecha | date }}</option>
                    </select>
                </div>

            </div>
        </div>

        <table class="table table-hover" *ngIf="packingList.length > 0">
            <thead>
                <tr>
                    <th>Cliente</th>
                    <th>Orden</th>
                    <th>Shipping</th>        
                    <th colspan="5">Salidas</th>
                    <th>F. Salida</th>
                    <th>Total</th>
                </tr>
                <tr>
                    <th></th>
                    <th></th>
                    <th></th>
                    <th width="100">Diseño</th>
                    <th width="100">SKU</th>
                    <th width="100">Lote</th>
                    <th width="100">Piezas</th>
                    <th width="100">Cajas</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let orden of packingList">
                    <td>{{ getCliente(orden.cliente_id) }}</td>
                    <td>{{orden.id}}</td>
                    <td>
                        <span *ngIf="orden.shipping.proveedor === 'otro'">{{ orden.shipping.otro_proveedor | titlecase }}</span>
                        <span *ngIf="orden.shipping.proveedor !== 'otro'">{{ orden.shipping.proveedor.split('_').join(' ') | titlecase }}</span>
                    </td>
                    <td colspan="5">
                        <table class="table interTable">
                            <tbody>
                                <tr *ngFor="let salida of orden.salidas">
                                   <td width="100">{{salida.producto.diseno}}</td> 
                                   <td width="100">{{salida.producto.sku}}</td> 
                                   <td width="100">{{salida.producto.lotes[0].pedido}}</td> 
                                   <td width="100">{{salida.cantidad}}</td>
                                   <td width="100">{{ salida.cantidad / salida.producto.caja.piezas }}</td>                                   
                                </tr>
                            </tbody>
                        </table>
                    </td>                    
                    <td>{{orden.fecha}}</td>
                    <td>{{orden.datos_pago.total | currency}}</td>
                </tr>
            </tbody>
        </table>
    </section>
    
</main>
