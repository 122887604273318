import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  productos: any = [];
  tramites = {
    entradas: [],
    salidas: []
  };

  constructor( private fb: FirebaseService ) { }

  async ngOnInit() {
    await this.fb.getProductos().then( response => {
      this.productos = response;
    }).catch( err => console.log('Error: ', err));

    await this.fb.getPalletes().then( (response: any) => {
      this.tramites.entradas = response ? response : [];
    }).catch( err => console.log('Error: ', err));

    await this.fb.getPalletsSold().then( (response: any) => {
      this.tramites.salidas = response ? response : [];
    }).catch( err => console.log('Error: ', err));

  }

  onCreatedProduct(ev) {
    console.log('Evento', ev);
    if (ev) {
      this.productos.push(ev);
    }
  }
}
