<div style="height: 45px;"></div>
<main class="container">
    <div class="row">
        <div class="col-lg-12">
            <h1 class="display-4">Medidas</h1>
        </div>
    </div>

    <div style="height: 25px;"></div>

    <div class="row">
        <div class="col-3">
            <button type="button" class="btn btn-outline-secondary btn-block" [routerLink]="['/app', 'productos']">Atrás</button>
        </div>
        <div class="col-3 offset-6">
            <button type="button" class="btn btn-outline-primary btn-block" data-toggle="modal" data-target="#modal-medida-crear">Nuevo</button>
        </div>
    </div>

    <div style="height: 25px;"></div>
    <section>
        <div *ngIf="!medidas.length">
            <p align="center">No hay medidas en la lista <a href="#" role="button" data-toggle="modal" data-target="#modal-medida-crear">agregar uno nuevo</a></p>
        </div>

        <div class="row" *ngIf="medidas && medidas.length">
            <div class="col-lg-12">
                <table class="table table-hover dataTables">
                    <thead>
                        <tr>
                            <td>Medidas</td>
                            <td>Descripción</td>
                            <td>Conv. Pies 3</td>
                            <td>Conv. m2</td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let m of medidas">
                            <td>{{m.nombre}}</td>
                            <td>{{m.descripcion}}</td>
                            <td>{{m.pies}}</td>
                            <td>{{m.m2}}</td>
                            <td>
                                <a href="#" data-toggle="modal" data-target="#modal-medida-editar" (click)="select(m)">
                                    <i class="material-icons">edit</i>
                                </a>
                            </td>
                            <td>
                                <a href="#" data-toggle="modal" data-target="#modal-medida-eliminar" (click)="select(m)">
                                    <i class="material-icons">delete</i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    </section>
</main>

<app-modal-medida-crear (onCreate)="onCreated($event)"></app-modal-medida-crear>
<app-modal-medida-editar [medida]="medida" (onUpdate)="onUpdated($event)"></app-modal-medida-editar>
<app-modal-medida-eliminar [medida]="medida" (onDelete)="onDeleted($event)"></app-modal-medida-eliminar>