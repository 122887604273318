<!-- Modal -->
<div class="modal fade" id="modal-producto-entradas" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title text-info" id="staticBackdropLabel" *ngIf="producto && producto.id">{{producto.diseno}}</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div *ngIf="producto && producto.palletes">
                <div class="row" *ngIf="producto.img">
                    <div class="col-4 offset-4">
                        <img [src]="producto.img" class="img-fluid" />
                    </div>
                </div>

                <div style="height: 45px;"></div>
                <div class="row" *ngIf="producto.palletes && producto.palletes.length">
                  <div class="col-lg-12">
                      <table class="table table-hover dataTables">
                          <thead>
                              <tr>
                                  <th>Pedido</th>
                                  <th>F. Solicitud</th>
                                  <th>F. Empaque</th>
                                  <th>Piezas</th>
                                  <th>F. Entrega</th>
                                  <th>Sold Pend.</th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let pallete of producto.palletes">
                                  <td>{{pallete.pedido}}</td>
                                  <td>{{pallete.fecha_solicitud}}</td>
                                  <td>{{pallete.fecha_empaque}}</td>
                                  <td>
                                    <span class="text-warning" *ngIf="pallete.piezas_vendidas ">{{ pallete.piezas - pallete.piezas_vendidas | number}}</span>
                                    <span *ngIf="!pallete.piezas_vendidas">{{ pallete.piezas | number}}</span>
                                  </td>
                                  <td>{{pallete.fecha_entrega}}</td>
                                  <td></td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
              </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
        </div>
      </div>
    </div>
  </div>