
<!-- Modal -->
<div class="modal fade" id="modal-producto-eliminar" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Eliminar Producto</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">

          <div *ngIf="producto && producto.id">
              <h4 align="center">
                  ¿Estás seguro que deseas eliminar este el producto <strong class="text-primary">{{producto.diseno}}</strong>?<br/>
                  El historial de entradas y salidas también será eliminado permanentemente.
              </h4>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-danger" (click)="submit()">Terminar</button>
        </div>
      </div>
    </div>
  </div>