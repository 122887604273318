<!-- Modal -->
<div class="modal fade" id="modal-orden-crear" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Nueva Orden</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="!order">
              <form [formGroup]="form" (ngSubmit)="submit()">
                <fieldset>
                    <legend>Orden</legend>
                    <div class="form-group">
                        <label>Fecha: *</label>
                        <input type="date" formControlName="fecha" class="form-control" placeholder="Fecha*" />
                    </div>
                    <div class="form-group">
                        <input type="text" formControlName="id" class="form-control" placeholder="# Orden*" />
                    </div>
                    <legend>Datos de Pago</legend>
                    <div formGroupName="datos_pago" class="row">
                        <div class="col-6">
                            <div class="form-group">
                                <label>Costo MXN: *</label>
                                <input type="digits" formControlName="total" class="form-control" placeholder="Total*" />
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label>Costo USD: *</label>
                                <input type="digits" formControlName="usd" class="form-control" placeholder="USD*" />
                            </div>                        
                        </div>
                    </div>
                    <div formGroupName="datos_pago" class="row">
                        <div class="col-12">                  
                            <div class="form-group">
                                <label>Método de Pago</label>
                                <select formControlName="metodo_pago" class="form-control">
                                    <option value="">- Selecciona un método -</option>
                                    <option *ngFor="let m of metodos_pago" [value]="m">{{ m.split('_').join(' ') | titlecase }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <legend>Datos de Envío</legend>
                    <div formGroupName="shipping" class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <select formControlName="proveedor" class="form-control" (change)="verifyProvider()">
                                    <option value="">- Selecciona un Proveedor</option>
                                    <option *ngFor="let p of proveedores" [value]="p">{{p.replace('_', ' ') | uppercase}}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="form.value.shipping.proveedor === 'otro'" class="row">
                        <div class="col-12">
                            <div class="form-group">
                                <label>Otro proveedor: </label>
                                <input type="text" id="otro_proveedor" class="form-control" placeholder="Nombre de Proveedor" />
                            </div>
                        </div>
                    </div>
                    <div formGroupName="shipping" class="row">
                        <div class="col-4">
                            <div class="form-group">
                                <label>Guía: </label>
                                <input type="text" formControlName="guia" class="form-control" placeholder="Guía" />
                            </div>                        
                        </div>
                        <div class="col-4">
                            <div class="form-group">
                                <label>Costo: </label>
                                <input type="digits" formControlName="costo" class="form-control" placeholder="Costo*" />
                            </div>
                        </div>                        
                        <div class="col-4">
                            <div class="form-group">
                                <label>Fecha de Entrega: </label>
                                <input type="date" formControlName="fecha_entrega" class="form-control" placeholder="AAAA-MM-DD" />
                            </div>
                        </div>
                    </div>
                    <div class="form-group">
                        <textarea formControlName="comentarios" class="form-control" placeholder="Comentarios adicionales"></textarea>
                    </div>
                </fieldset>
            </form>
          </div>
          

          <div *ngIf="order">
              <form [formGroup]="searchForm" (ngSubmit)="buscarProductos()">
                <fieldset>
                    <div class="form-group">
                        <input type="text" id="autocomplete" formControlName="keywordSearch" class="form-control" placeholder="Buscar Productos" [(ngModel)]="keywordSearch" (change)="buscarProductos()" />
                    </div>
                </fieldset>
              </form>
              <div *ngIf="lista.length">
                  <p>{{lista.length}} Resultados que coinciden</p>
                  <hr />
                  <div *ngFor="let producto of lista">
                      <div class="row">
                          <div class="col-lg-6">
                            <p align="center">{{ producto.diseno }}</p>
                          </div>
                          <div class="col-lg-4">
                            <p align="center">{{cuentaPiezas(producto.lotes)}} Piezas / {{ producto.lotes.length }} Lote(s)</p>
                          </div>
                          <div class="col-lg-2">
                            <a href="#" role="button" data-toggle="modal" (click)="addProduct(producto)">
                                <i class="material-icons">add</i>
                            </a>
                          </div>
                      </div>
                  </div>
                  <!--
                  <table class="table table-hover">
                      <thead>
                          <tr>
                              <th>Diseño</th>
                              <th>Lote</th>
                              <th>En Stock</th>
                              <th>Cantidad</th>
                              <th></th>
                          </tr>
                      </thead>
                      <tbody>
                          <tr *ngFor="let producto of lista">
                            <td>
                                {{ producto.diseno }}
                            </td>
                            <td>
                                <select id="lote{{producto.id}}" [(ngModel)]="lote" (change)="getStock()">
                                    <option>-</option>
                                    <option *ngFor="let l of producto.lotes" [value]="l.id">{{l.pedido}}</option>
                                </select>
                            </td>
                            <td>
                              <p align="right" class="text-info"><span id="loteStock{{producto.id}}">0</span> en stock</p>
                            </td>
                            <td>
                                <input id="producto{{producto.id}}" type="digits" class="form-control" placeholder="0" />
                            </td>
                            <td>
                                <a href="#" role="button" data-toggle="modal" (click)="addProduct(producto)">
                                    <i class="material-icons">add</i>
                                </a>
                            </td>
                          </tr>
                      </tbody>
                    </table>
                    -->
                </div>

                <div *ngIf="salidas.length">
                    <table class="table table-hover">
                        <thead>
                            <tr>
                                <th>Diseño</th>
                                <th>Lote</th>
                                <th>Stock</th>
                                <th>Cantidad</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let item of salidas">
                            <td>
                                {{ item.producto.diseno }}
                            </td>
                            <td>
                                <div class="form-group">
                                    <select class="form-control" name="lote{{item.producto.id}}" [(ngModel)]="item.entrada_id" (change)="getStock(item.producto.id)">
                                        <option>-</option>
                                        <option *ngFor="let l of item.producto.lotes" [value]="l.id">{{l.pedido}}</option>
                                    </select>
                                </div>
                            </td>
                            <td>
                                {{ item.stock && !isNaN(item.stock) ? item.stock : 0 }}
                            </td>
                            <td>
                                <input type="number" class="form-control" name="cantidad{{item.producto.id}}" [(ngModel)]="item.cantidad" min="1" [max]="item.stock" placeholder="0" />
                            </td>
                            <td>
                                <a href="#" role="button" data-toggle="modal" (click)="removeProduct(item.producto.id)">
                                    <i class="material-icons">remove</i>
                                </a>
                            </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="submit()">Continuar</button>
        </div>
      </div>
    </div>
  </div>