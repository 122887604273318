import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-salidas',
  templateUrl: './salidas.component.html',
  styleUrls: ['./salidas.component.css']
})
export class SalidasComponent implements OnInit {
  salida: any;
  salidas: Array<any> = [];
  ordenes: Array<any> = [];

  constructor( private fb: FirebaseService, private us: UserService ) { }

  ngOnInit(): void {
    this.cargarSalidas();
  }

  async cargarSalidas() {
    await this.fb.getSalidas().then( (callback: Array<any>) => {
    
      this.salidas = callback.map( (salida: any) => {
        this.fb.getOrdenes(null, salida.orden_id).then( (orden: any) => {
          salida.orden = orden;

          //Obtiene cliente
          this.fb.getClientes(orden.cliente_id).then( data => {
            salida.cliente = data;
          });

        });

        this.fb.getPallet(salida.entrada_id).then( (pallet: any) => {
          salida.pallet = pallet;
          this.fb.getProductoPallete(pallet.pid).then( data => {
            //console.log('data?', data);
            salida.producto = data;
          });
        });

        return salida;
      });      
    });

    this.us.setDataTables();
  }

  async cargarOrdenes() {
    await this.fb.getOrdenes().then( (callback: Array<any>) => {
      
      this.ordenes = callback.map( orden => {
        this.fb.getClientes(orden.cliente_id).then( data => {
          orden.cliente = data;
        });

        this.fb.getSalidas(orden.id).then( (salidas: Array<any>) => {
          orden.salidas = salidas && salidas.length ? salidas.map( salida => {
            this.fb.getPallet(salida.entrada_id).then( (pallet: any) => {
              
              salida.pallet = pallet;
              this.fb.getProductoPallete(pallet.pid).then( data => {
                //console.log('data?', data);
                salida.producto = data;
              });
            });

            return salida;
          }) : [];
        });
        return orden;
      });

      console.log('Ordenes: ', this.ordenes);
    });
  }

  fixCajas(cajas: Number) {
    return cajas.toFixed(2);
  }

  getFechaEntrega(fecha) {
    const newDate = fecha.split('/').length > 1 ? fecha.split('/').reverse().join('-') : fecha;
    return newDate;
  }

  showUpdate(salida) {
    this.salida = salida;
  }

  onUpdated(salida) {
    this.salidas = this.salidas.filter( s => s.id !== salida.id );
    this.salidas.push(salida);
  }

}
