import { Component, OnInit, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-modal-producto-consulta',
  templateUrl: './modal-producto-consulta.component.html',
  styleUrls: ['./modal-producto-consulta.component.css']
})
export class ModalProductoConsultaComponent implements OnInit, OnChanges {
  @Input() pallete;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
  }

}
