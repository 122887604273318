import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';

@Component({
  selector: 'app-modal-orden-eliminar',
  templateUrl: './modal-orden-eliminar.component.html',
  styleUrls: ['./modal-orden-eliminar.component.css']
})
export class ModalOrdenEliminarComponent implements OnInit {
  @Output() onDelete = new EventEmitter<any>();
  @Input() orden: any;

  constructor( private fb: FirebaseService ) { }

  ngOnInit(){

  }

  deleteOrder() {
    this.fb.deleteOrden(this.orden.id);
    this.onDelete.emit(this.orden);
  }

}
