import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './auth/auth.component';
import { SystemComponent } from './system/system.component';
import { ReportesComponent } from './system/reportes/reportes.component';
import { ProductosComponent } from './system/productos/productos.component';
import { DashboardComponent } from './system/dashboard/dashboard.component';
import { EntradasComponent } from './system/entradas/entradas.component';
import { SalidasComponent } from './system/salidas/salidas.component';
import { MedidasComponent } from './system/productos/medidas/medidas.component';
import { ClientesComponent } from './system/clientes/clientes.component';
import { OrdenesComponent } from './system/ordenes/ordenes.component';
import { PackingListComponent } from './system/salidas/packing-list/packing-list.component';


const routes: Routes = [
  { path: 'app', component: SystemComponent, children: [
    { path: 'medidas', component: MedidasComponent }, 
    { path: 'productos', component: ProductosComponent },  
    { path: 'reportes', component: ReportesComponent },  
    { path: 'entradas', component: EntradasComponent },  
    { path: 'salidas', component: SalidasComponent },  
    { path: 'salidas/packing-list', component: PackingListComponent },  
    { path: 'clientes', component: ClientesComponent },
    { path: 'ordenes/:id', component: OrdenesComponent },
    { path: '', component: DashboardComponent },  
  ]},
  { path: '', component: AuthComponent },
  { path: '**', component: AuthComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
