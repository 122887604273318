import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DataTablesModule } from 'angular-datatables';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxNotifierModule } from 'ngx-notifier';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { NgxSpinnerModule } from "ngx-spinner";
//import * as echarts from 'echarts';

import { StorageServiceModule } from 'ngx-webstorage-service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthComponent } from './auth/auth.component';
import { SystemComponent } from './system/system.component';
import { ProductosComponent } from './system/productos/productos.component';
import { ReportesComponent } from './system/reportes/reportes.component';
import { HeaderComponent } from './core/header/header.component';
import { FooterComponent } from './core/footer/footer.component';
import { DashboardComponent } from './system/dashboard/dashboard.component';
import { ModalCrearProductoComponent } from './modals/productos/modal-crear-producto/modal-crear-producto.component';

import * as firebase from 'firebase';
import { ModalProductoCrearComponent } from './modals/productos/modal-producto-crear/modal-producto-crear.component';
import { EntradasComponent } from './system/entradas/entradas.component';
import { SalidasComponent } from './system/salidas/salidas.component';
import { ImageCropperComponent } from './helpers/image-cropper/image-cropper.component';
import { ModalProductoEditarComponent } from './modals/productos/modal-producto-editar/modal-producto-editar.component';
import { ModalEntradaCrearComponent } from './modals/entradas/modal-entrada-crear/modal-entrada-crear.component';
import { ModalProductoConsultaComponent } from './modals/productos/modal-producto-consulta/modal-producto-consulta.component';
import { ModalProductoEntradasComponent } from './modals/productos/modal-producto-entradas/modal-producto-entradas.component';
import { ModalEntradaEditarComponent } from './modals/entradas/modal-entrada-editar/modal-entrada-editar.component';
import { ModalEntradaEliminarComponent } from './modals/entradas/modal-entrada-eliminar/modal-entrada-eliminar.component';
import { ModalProductoEliminarComponent } from './modals/productos/modal-producto-eliminar/modal-producto-eliminar.component';
import { MedidasComponent } from './system/productos/medidas/medidas.component';
import { ModalMedidaCrearComponent } from './modals/medidas/modal-medida-crear/modal-medida-crear.component';
import { ModalMedidaEditarComponent } from './modals/medidas/modal-medida-editar/modal-medida-editar.component';
import { ModalMedidaEliminarComponent } from './modals/medidas/modal-medida-eliminar/modal-medida-eliminar.component';
import { ClientesComponent } from './system/clientes/clientes.component';
import { ModalClienteCrearComponent } from './modals/clientes/modal-cliente-crear/modal-cliente-crear.component';
import { ModalClienteEditarComponent } from './modals/clientes/modal-cliente-editar/modal-cliente-editar.component';
import { ModalClienteEliminarComponent } from './modals/clientes/modal-cliente-eliminar/modal-cliente-eliminar.component';
import { OrdenesComponent } from './system/ordenes/ordenes.component';
import { ModalOrdenCrearComponent } from './modals/ordenes/modal-orden-crear/modal-orden-crear.component';
import { ModalOrdenEditarComponent } from './modals/ordenes/modal-orden-editar/modal-orden-editar.component';
import { ModalOrdenEliminarComponent } from './modals/ordenes/modal-orden-eliminar/modal-orden-eliminar.component';
import { ModalOrdenConsultaComponent } from './modals/ordenes/modal-orden-consulta/modal-orden-consulta.component';
import { ModalSalidasEditarComponent } from './modals/salidas/modal-salidas-editar/modal-salidas-editar.component';
import { PackingListComponent } from './system/salidas/packing-list/packing-list.component';

var firebaseConfig = {
  apiKey: "AIzaSyB7bLk4diQh6pu1hHhn2lyfv9Y7GqZ3EZI",
  authDomain: "omt-stock.firebaseapp.com",
  databaseURL: "https://omt-stock.firebaseio.com",
  projectId: "omt-stock",
  storageBucket: "omt-stock.appspot.com",
  messagingSenderId: "420773943677",
  appId: "1:420773943677:web:5371f139ce94b056a2d414",
  measurementId: "G-VVHB5VJW76"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

@NgModule({
  declarations: [
    AppComponent, AuthComponent, HeaderComponent, FooterComponent,
    SystemComponent, ProductosComponent, ReportesComponent, DashboardComponent, ModalCrearProductoComponent, ModalProductoCrearComponent, EntradasComponent, SalidasComponent, ImageCropperComponent, ModalProductoEditarComponent, ModalEntradaCrearComponent, ModalProductoConsultaComponent, ModalProductoEntradasComponent, ModalEntradaEditarComponent, ModalEntradaEliminarComponent, ModalProductoEliminarComponent, MedidasComponent, ModalMedidaCrearComponent, ModalMedidaEditarComponent, ModalMedidaEliminarComponent, ClientesComponent, ModalClienteCrearComponent, ModalClienteEditarComponent, ModalClienteEliminarComponent, OrdenesComponent, ModalOrdenCrearComponent, ModalOrdenEditarComponent, ModalOrdenEliminarComponent, ModalOrdenConsultaComponent, ModalSalidasEditarComponent, PackingListComponent,
  ],
  imports: [
    BrowserModule, NgxSpinnerModule,
    AppRoutingModule,
    DataTablesModule,
    FormsModule,
    ReactiveFormsModule, ImageCropperModule,
    StorageServiceModule, BrowserAnimationsModule, NgxNotifierModule, AutocompleteLibModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
