<!-- Modal -->
<div class="modal fade" id="modal-cliente-eliminar" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Eliminar Cliente</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div *ngIf="cliente && cliente.id">
            <h4 align="center">
              ¿Estás seguro que deseas eliminar el cliente <strong class="text-primary">{{cliente.nombre}}</strong>?<br/>
              Este cambio es permanente.
            </h4>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-danger" (click)="submit()">Terminar</button>
        </div>
      </div>
    </div>
  </div>