<div style="height: 45px;"></div>

<main class="container">
    <div class="row">
        <div class="col-lg-12">
            <h1 class="display-4">Reportes</h1>
        </div>
    </div>

    <div style="height: 25px;"></div>

    <div class="row">
        <div class="col-3">
            <div class="form-group">
                Tipo de Ingreso:
                <select [(ngModel)]="currency" class="form-control" (change)="actualizarAno()">
                    <option value="MX" selected>MXN</option>
                    <option value="US">USD</option>                    
                </select>
            </div>
        </div>
        <div class="col-3 offset-6">
            <div class="form-group">
                Año:
                <select [(ngModel)]="ano" class="form-control" (change)="actualizarAno()">
                    <option value="2021" selected>2021</option>
                    <option value="2020">2020</option>
                </select>
            </div>
        </div>
    </div>
    <canvas id="myChart"></canvas>

    <div style="height: 45px;"></div>
    <h2>Recaudaciones</h2>
    <canvas id="myChart2"></canvas>

    <div style="height: 45px;"></div>
    <h2>Diseños más vendidos</h2>
    <canvas id="myChart3"></canvas>

    <div style="height: 45px;"></div>
    <h2>Clientes</h2>
    <canvas id="myChart4"></canvas>
</main>