import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  session: any;

  constructor( private auth: AuthService, private router: Router ) { }

  ngOnInit(): void {
    this.session = this.auth.getAuth() ? this.auth.getAuth() : null;
    this.validateLogin();
  }

  validateLogin() {
    if( !this.session ){
      setInterval(() => {
        this.session = this.auth.getAuth() ? this.auth.getAuth() : null;
      }, 3000);    
      return 1;
    }

    return 1;
    
  }

  logOut() {
    this.auth.signOut().then(() => {
      this.session = null;
      this.router.navigate(['']);
    }).catch();
  }

}
