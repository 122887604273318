import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxNotifierService } from 'ngx-notifier';
import { Cliente } from 'src/app/models/cliente.model';
import { FirebaseService } from 'src/app/services/firebase.service';

declare var $:any;

@Component({
  selector: 'app-modal-cliente-editar',
  templateUrl: './modal-cliente-editar.component.html',
  styleUrls: ['./modal-cliente-editar.component.css']
})
export class ModalClienteEditarComponent implements OnInit, OnChanges {
  form: FormGroup;
  @Input() cliente: Cliente;
  @Output() onUpdate = new EventEmitter<any>();

  constructor(private fb: FirebaseService, private notifier: NgxNotifierService) { }

  ngOnInit(): void {
    this.initform();
  }

  ngOnChanges() {
    if (this.cliente && this.cliente.id ) {
      this.form.patchValue({
        nombre: this.cliente.nombre,
        email: this.cliente.email,
        telefono: this.cliente.telefono,
        status: this.cliente.status,
        empresa: this.cliente.empresa,
        estado: this.cliente.estado,
        ciudad: this.cliente.ciudad,
        cp: this.cliente.cp,
        direccion: this.cliente.direccion,
        rfc: this.cliente.rfc,
        notas: this.cliente.notas
      });
    }
  }

  initform() {
    this.form = new FormGroup({
      nombre: new FormControl("", Validators.required),
      email: new FormControl("", Validators.required),
      telefono: new FormControl("", Validators.required),
      empresa: new FormControl("", Validators.required),
      ciudad: new FormControl("", Validators.required),
      estado: new FormControl("", Validators.required),
      cp: new FormControl("", Validators.required),
      status: new FormControl("", Validators.required),
      direccion: new FormControl(""),
      rfc: new FormControl("", Validators.maxLength(13)),
      notas: new FormControl("", Validators.maxLength(500)),
    });
  }

  submit() {
    if( this.form.valid ) {
      this.fb.putCliente(this.cliente.id, this.form.value).then((response: Cliente)=> {
        if( response ) {
          this.notifier.createToast("El cliente " + this.form.value.nombre + " se ha actualizado correctamente", "success", 3000);
          this.cliente = { ...response, alta:this.cliente.alta };
          this.onUpdate.emit(this.cliente);
          this.form.reset();
          $('.modal').modal('hide');
        } else{
          this.notifier.createToast("Lo sentimos ocurrió un problema en actualizar al cliente", "warning", 3000)    
        }
      }).catch(() => {
        this.notifier.createToast("Lo sentimos ocurrió un problema en actualizar al cliente", "danger", 3000)    
      });
    } else { 
      this.notifier.createToast("Favor de llenar los datos solicitados.", "danger", 3000)
    }
  }
}
