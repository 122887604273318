import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { FirebaseService } from 'src/app/services/firebase.service';
import { NgxNotifierService } from 'ngx-notifier';

@Component({
  selector: 'app-medidas',
  templateUrl: './medidas.component.html',
  styleUrls: ['./medidas.component.css']
})
export class MedidasComponent implements OnInit {
  medidas = [];
  medida: any;

  constructor( private fb: FirebaseService, private us: UserService, private notifier: NgxNotifierService ) { }

  async ngOnInit() {
    this.notifier.createToast("Cargando medidas", 'info', 3000);
    await this.fb.getMedidas().then( (callback: any) => {
      this.medidas = callback ? callback : [];
      this.us.setDataTables();
    }).catch( e => console.log('Error:', e));
  }

  select(p) {
    this.medida = p;
  }

  onCreated(ev) {
    if (ev) {
      this.medidas.push(ev);
      this.us.setDataTables();
    }
  }

  onUpdated(ev) {
    if (ev) {
      this.medidas = this.medidas.filter(p => p.id !== ev.id);
      this.medidas.push(ev);
      this.us.setDataTables();
    }
  }

  onDeleted(ev) {
    if (ev) {
      this.medidas = this.medidas.filter(p => p.id !== ev.id);
      this.us.setDataTables();
    }
  }

}
