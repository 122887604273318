import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modal-orden-editar',
  templateUrl: './modal-orden-editar.component.html',
  styleUrls: ['./modal-orden-editar.component.css']
})
export class ModalOrdenEditarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
