import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { NgxNotifierService } from 'ngx-notifier';
import { Cliente } from 'src/app/models/cliente.model';
import { FirebaseService } from 'src/app/services/firebase.service';

declare var $:any;

@Component({
  selector: 'app-modal-cliente-eliminar',
  templateUrl: './modal-cliente-eliminar.component.html',
  styleUrls: ['./modal-cliente-eliminar.component.css']
})
export class ModalClienteEliminarComponent implements OnInit, OnChanges {
  @Input() cliente: Cliente;
  @Output() onDelete = new EventEmitter<any>();

  constructor(private fb: FirebaseService, private notifier: NgxNotifierService) { }

  ngOnInit(): void {  }

  ngOnChanges() {
    if (this.cliente && this.cliente.id ) {

    }
  }

  submit() {
    if( this.cliente && this.cliente.id ) {
      this.fb.deleteCliente(this.cliente.id).then((response: Boolean)=> {
        if( response ) {
          this.notifier.createToast("El cliente " + this.cliente.nombre + " se ha eliminado correctamente", "success", 3000);
          this.onDelete.emit(this.cliente.id);
          $('.modal').modal('hide');
        } else{
          this.notifier.createToast("Lo sentimos ocurrió un problema en eliminar al cliente", "warning", 3000)    
        }
      }).catch(() => {
        this.notifier.createToast("Lo sentimos ocurrió un problema en eliminar al cliente", "danger", 3000)    
      });
    } else { 
      this.notifier.createToast("Favor de seleccionar un usuario.", "danger", 3000)
    }
  }

}
