import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { NgxNotifierService } from 'ngx-notifier';
import { FormGroup, Validators, FormControl } from '@angular/forms';

declare var $:any;

@Component({
  selector: 'app-modal-medida-editar',
  templateUrl: './modal-medida-editar.component.html',
  styleUrls: ['./modal-medida-editar.component.css']
})
export class ModalMedidaEditarComponent implements OnInit, OnChanges {
  @Input() medida;
  @Output() onUpdate = new EventEmitter<any>();
  form: FormGroup;

  constructor( private fb: FirebaseService, private notifier: NgxNotifierService) { }

  ngOnInit() {
    this.initForm();
  }

  ngOnChanges() {
    if (this.medida && this.medida.id) {
      this.form.patchValue({
        nombre: this.medida.nombre,
        descripcion: this.medida.descripcion,
        pies: this.medida.pies,
        m2: this.medida.m2,
      });
    }
  }

  initForm() {
    this.form = new FormGroup({
      nombre: new FormControl("", Validators.required),
      descripcion: new FormControl(""),
      pies: new FormControl("", Validators.required),
      m2: new FormControl("", Validators.required)
    });
  }

  async submit(){
    if ( this.form.valid ){
      let data: any = this.form.value;
      await this.fb.putMedida(this.medida.id, data).then( () => {
        this.notifier.createToast("Medida editada correctamente", 'success');
        data.id = this.medida.id;
        this.onUpdate.emit( data );
        this.form.reset();
        $('.modal').modal('hide');
      }).catch( err => console.log('Error: ', err));
      
    } else{
      this.notifier.createToast("Falta información", 'error');
    }
  }

}
