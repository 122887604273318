import { Component, OnInit, Input } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { FormGroup } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-modal-producto-entradas',
  templateUrl: './modal-producto-entradas.component.html',
  styleUrls: ['./modal-producto-entradas.component.css']
})
export class ModalProductoEntradasComponent implements OnInit {
  @Input() producto;
  form: FormGroup;
  pallets = [];

  constructor( private fb: FirebaseService, private us: UserService ) { }

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    if ( this.producto && this.producto.id ) {
      //console.log('Palletes', this.producto.palletes);

      this.fb.getPalletes( this.producto.id ).then( pallets => {
        //console.log('Pallets', pallets);
        this.producto.palletes = pallets;
      }).catch(e => console.log('Error: ', e));

      this.us.setDataTables();
    }
  }

}
