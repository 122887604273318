<!-- Modal -->
<div class="modal fade" id="modal-cliente-editar" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Editar Cliente</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="form" (ngSubmit)="submit()">
            <fieldset>
              <div class="form-group">
                  <label>Nombre: *</label>
                  <input formControlName="nombre" type="text" class="form-control" placeholder="Nombre*" />
              </div>
              <div class="form-group">
                  <label>Email: *</label>
                  <input formControlName="email" type="email" class="form-control" placeholder="Email*" />
              </div>
              <div class="form-group">
                <label>Status: *</label>
                <select formControlName="status" class="form-control">
                  <option value="activo">Activo</option>
                  <option value="inactivo">Inactivo</option>
                </select>
            </div>
              <div class="form-group">
                  <label>Teléfono: *</label>
                  <input formControlName="telefono" type="digits" class="form-control" placeholder="4440000000*" />
              </div>
              <legend>Datos Empresa</legend>
              <div class="form-group">
                  <label>RFC: </label>
                  <input formControlName="rfc" type="text" class="form-control" placeholder="RFC" />
              </div>
              <div class="form-group">
                  <label>Nombre: *</label>
                  <input formControlName="empresa" type="text" class="form-control" placeholder="Nombre de la Empresa*" />
              </div>
              <div class="form-group">
                  <label>Dirección: </label>
                  <input formControlName="direccion" type="text" class="form-control" placeholder="Dirección" />
              </div>
              <div class="form-group">
                <label>Ciudad: </label>
                <input formControlName="ciudad" type="text" class="form-control" placeholder="Ciudad" />
              </div>
              <div class="form-group">
                <label>Estado: </label>
                <input formControlName="estado" type="text" class="form-control" placeholder="Estado" />
              </div>
              <div class="form-group">
                <label>Código Postal: </label>
                <input formControlName="cp" type="digits" class="form-control" placeholder="Código Postal" />
              </div>
              <div class="form-group">
                  <label>Notas: </label>
                  <textarea formControlName="notas" class="form-control" placeholder="Agregar Notas"></textarea>
              </div>    
            </fieldset>
        </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cerrar</button>
          <button type="button" class="btn btn-primary" (click)="submit()">Guardar Cambios</button>
        </div>
      </div>
    </div>
  </div>