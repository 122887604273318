
<!-- Modal -->
<div class="modal fade" id="modal-producto-editar" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Editar Producto</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-6 offset-3">
                    <app-image-cropper [path]="path" (onUpload)="onUploaded($event)"></app-image-cropper>
                </div>
            </div>

          <form [formGroup]="form" (ngSubmit)="submit()">
            <fieldset>
                <div class="form-group">
                    <label>Diseño: *</label>
                    <input type="text" formControlName="diseno" class="form-control" placeholder="Diseño*" />
                </div>
                <div class="form-group">
                    <label>Medidas: *</label>
                    <input type="text" formControlName="medidas" class="form-control" placeholder="Medidas" />
                </div>
                <div class="form-group">
                    <label>SKU: *</label>
                    <input type="text" formControlName="sku" class="form-control" placeholder="SKU*" />
                </div>
                <div class="form-group">
                    <label>Piezas: </label>
                    <input type="numeric" formControlName="stock" class="form-control" placeholder="# Piezas*" />
                </div>   
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Min. Cajas: </label>
                      <input type="numeric" formControlName="min_cajas" class="form-control" placeholder="Min. Cajas*" />
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label>Max. Cajas: </label>
                      <input type="numeric" formControlName="max_cajas" class="form-control" placeholder="Max. Cajas*" />
                    </div>
                  </div>
                </div>
                <div class="form-group">
                    <label>Descripción: </label>
                    <textarea class="form-control" formControlName="descripcion" placeholder="Descripción del diseño"></textarea>
                </div>
                <div class="form-group">
                  <label>Medida de Conversión: *</label>
                  <select formControlName="mid" class="form-control">
                    <option value=""> --- </option>
                    <option *ngFor="let m of medidas" [value]="m.id"> {{ m.nombre}} ({{m.pies}} pies x {{m.m2}} m2) </option>
                  </select>
                </div>
                <div formGroupName="caja">
                  <div class="form-group">
                    <label>Piezas por Caja: *</label>
                    <input type="numeric" formControlName="piezas" class="form-control" placeholder="Piezas x Caja*" />
                  </div>
                </div>
            </fieldset>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="submit()">Guardar Cambios</button>
        </div>
      </div>
    </div>
  </div>