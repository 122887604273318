<div style="height: 45px;"></div>
<main class="container">
    <!--<div class="row">
        <div class="col-lg-12">
            <h1 class="display-4">Dashboard</h1>

            <div style="height: 25px;"></div>
        </div>
    </div>-->

    <section class="main-title">
        <div class="row">
            <div class="col-lg-6">
                <h1 class="display-4">Dashboard</h1>
            </div>
            <div class="col-lg-3">
                <br>
                <h4 align="center">
                    <a href="#" [routerLink]="['/app', 'salidas', 'packing-list']">
                        <i class="material-icons">local_shipping</i> Packing List
                    </a>
                </h4>
            </div>
            <div class="col-lg-3">
                <br />
                <div class="btn-group btn-block">
                    <button type="button" class="btn btn-outline-primary btn-block">Nuevo</button>
                    <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="caret"></span>
                        <span class="sr-only">Toggle Dropdown</span>
                      </button>
                      <ul class="dropdown-menu">
                        <li>
                            <a href="#" data-toggle="modal" data-target="#modal-producto-crear">
                                <i class="material-icons">label</i> Producto
                            </a>
                        </li>
                        <li>
                            <a href="#" data-toggle="modal">
                                <i class="material-icons">assignment_returned</i> Entrada
                            </a>
                        </li>
                        <li>
                            <a href="#" data-toggle="modal">
                                <i class="material-icons">assignment_return</i> Salida
                            </a>
                        </li>
                        <li role="separator" class="divider"></li>
                        <li>
                            <a href="#" data-toggle="modal">
                                <i class="material-icons">contact_page</i> Cliente
                            </a>
                        </li>
                        <li>
                            
                        </li>
                      </ul>
                </div>
                
            </div>
        </div>
        
        <div style="height: 45px;"></div>

        <div class="jumbotron">
            <div class="row">
                <div class="col-lg-4">

                    <div style="height: 25px;"></div>

                    <a href="#" [routerLink]="['/app', 'productos']">
                        <h4>{{ productos.length }}</h4>
                        <p class="text-primary">Productos</p>
                    </a>

                </div>
                <div class="col-lg-4">

                    <div style="height: 25px;"></div>                    
                    <a href="#" [routerLink]="['/app', 'entradas']">
                        <h4>{{ tramites.entradas.length }}</h4>
                        <p class="text-primary">Entradas</p>
                    </a>

                </div>
                <div class="col-lg-4">

                    <div style="height: 25px;"></div>
                    <a href="#" [routerLink]="['/app', 'salidas']">
                        <h4>{{ tramites.salidas.length }}</h4>
                        <p class="text-primary">Salidas</p>
                    </a>
                    
                </div>
            </div>
        </div>
        
    </section>

    <div style="height: 100px;"></div>

    <!--
    <section>
        <div class="row">
            <div class="col-6 offset-3">
                <h2 align="center">Trámites</h2>
            </div>
            <div class="col-3">
                <button type="button" class="btn btn-outline-primary btn-block">Nuevo</button>
            </div>
        </div>

        
        <div style="height: 45px;"></div>

        <table class="table table-hover">
            <thead>
                <tr>
                    <th>Tipo (Entrada / Salida)</th>
                    <th>Status</th>
                    <th>Comentarios</th>
                </tr>               
            </thead>
            <tbody>

            </tbody>
        </table>
    </section>
-->
</main>

<app-modal-producto-crear (onCreate)="onCreatedProduct($event)"></app-modal-producto-crear>