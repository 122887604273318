import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { UserService } from 'src/app/services/user.service';
import { NgxNotifierService } from 'ngx-notifier';
import { NgxSpinnerService } from 'ngx-spinner';

declare var XLSX: any;
@Component({
  selector: 'app-entradas',
  templateUrl: './entradas.component.html',
  styleUrls: ['./entradas.component.css']
})
export class EntradasComponent implements OnInit {
  palletes: any = [];
  pallete;

  constructor( private fb: FirebaseService, private us: UserService, private notifier: NgxNotifierService, private spinner: NgxSpinnerService ) { }

  async ngOnInit() {
    this.spinner.show();
    const pallets: any = await this.fb.getPalletes();
    const products: any = await this.fb.getProductos();

    for(let i = 0; i < pallets.length; i++ ) { //this.palletes.length
      let newPallet = pallets[i];

      const productExist = products.filter( (product: any) => product.id === newPallet.pid );
      newPallet.producto = productExist.length ? productExist[0] : null;

      if( !newPallet.producto ) {
        continue;
      }

      const product = newPallet.producto;
      newPallet.producto = newPallet.piezas && product.caja.piezas ? product : {...product, caja: { piezas: 0 } };
      this.palletes.push(newPallet);
    }

    this.us.setDataTables();
    this.spinner.hide();
  }

  select(p){
    this.pallete = p;
  }

  onUpdated(ev) {
    if (ev) {
      this.palletes = this.palletes.filter(p => p.id !== ev.id);
      this.palletes.push(ev);
      this.us.setDataTables();
    }
  }

  onDeleted(ev) {
    if (ev) {
      this.palletes = this.palletes.filter(p => p.id !== ev.id);
      this.us.setDataTables();
    }
  }

  getTagColores(colores){
    const col = colores ? colores.split(',') : [];
    colores = "";
    for(const a of col) {
      colores += `<span class="badge badge-info">` + a.toLowerCase() + `</span><br>`;
    }

    return colores;
  }

  exportToExcel(type, fn?, dl?) {
    var elt = document.getElementById('table_pallets_export');
    var wb = XLSX.utils.table_to_book(elt, { sheet: "datos" });
    return dl ?
      XLSX.write(wb, { bookType: type, bookSST: true, type: 'base64' }):
      XLSX.writeFile(wb, fn || ('Pallets.' + (type || 'xlsx')));
  }
}
