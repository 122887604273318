import { Component, OnInit, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Producto } from 'src/app/models/producto.model';
import { FirebaseService } from 'src/app/services/firebase.service';
import { NgxNotifierService } from 'ngx-notifier';

declare var $:any;

@Component({
  selector: 'app-modal-producto-editar',
  templateUrl: './modal-producto-editar.component.html',
  styleUrls: ['./modal-producto-editar.component.css']
})
export class ModalProductoEditarComponent implements OnInit, OnChanges {
  path: string = "mosaicos";
  @Input() producto;
  @Input() medidas;
  @Output() onUpdate = new EventEmitter<any>();
  form: FormGroup;

  constructor( private fb: FirebaseService, private notifier: NgxNotifierService ) { }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges() {
    if ( this.producto && this.producto.id ) {
      //Patch Value
      this.form.patchValue({
        diseno: this.producto.diseno,
        medidas: this.producto.medidas,
        mid: this.producto.mid,
        sku: this.producto.sku,
        stock: this.producto.stock,
        min_cajas: this.producto.min_cajas ? this.producto.min_cajas : 0,
        max_cajas: this.producto.max_cajas ? this.producto.max_cajas : 0,
        venta: this.producto.venta,
        descripcion: this.producto.descripcion,
        caja: {
          piezas: this.producto.caja.piezas,
          mpies3: this.producto.caja.mpies3,
          m2: this.producto.caja.m2
        }
      });
    }
  }

  initForm() {
    this.form = new FormGroup({
      diseno: new FormControl("", Validators.required),
      medidas: new FormControl(""),
      sku: new FormControl("", Validators.required),
      mid: new FormControl("", Validators.required),
      stock: new FormControl("", Validators.required),
      min_cajas: new FormControl("", Validators.required),
      max_cajas: new FormControl("", Validators.required),
      venta: new FormControl(""),
      descripcion: new FormControl("", [Validators.maxLength(1000)]),
      caja: new FormGroup({
        piezas: new FormControl("", Validators.required)
      })
    });
  }

  async submit(){
    if ( this.form.valid ){
      let data: Producto = this.form.value;
      data.venta = data.stock;

      await this.fb.putProducto(this.producto.id, data).then( (producto: any) => {
        this.notifier.createToast("Producto actualizado correctamente", 'success');
        producto.id = this.producto.id;
        producto.palletes = this.producto.palletes;
        this.onUpdate.emit( producto );
        this.form.reset();
        $('.modal').modal('hide');
      }).catch( err => console.log('Error: ', err));
      
    } else{
      this.notifier.createToast("Falta información", 'error');
    }
  }

  async onUploaded(event) {
    const data = {
      img : event
    };

    await this.fb.putProducto(this.producto.id, data).then( producto => {
      this.producto.img = event;
      this.notifier.createToast("Imagen actualizada correctamente", 'success');
    }).catch( err => console.log('Error: ', err));
  }
}
