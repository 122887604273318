<!-- Modal -->
<div class="modal fade" id="modal-entrada-crear" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Nuevo Pallet</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="form" (ngSubmit)="submit()">
              <fieldset>
                <div class="form-group">
                    <input type="text" formControlName="pedido" class="form-control" placeholder="Lote*" />
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group">
                            <label>Fecha de Solicitud: *</label>
                            <input type="date" formControlName="fecha_solicitud" class="form-control" placeholder="Fecha de Solicitud*" />
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="form-group">
                            <label>Ship Date: *</label>
                            <input type="date" formControlName="fecha_empaque" class="form-control" placeholder="Fecha de Empaque*" />
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Fecha de Entrega: </label>
                    <input type="date" formControlName="fecha_entrega" class="form-control" placeholder="Fecha de Entrega" />
                </div>
                <div class="form-group">
                    <input type="digits" formControlName="piezas" class="form-control" placeholder="Piezas*" />
                </div>
                <div class="form-group">
                    <textarea formControlName="comentarios" class="form-control" placeholder="Comentarios de Palette"></textarea>
                </div>
                <div class="form-group">
                  <label>Colores Diponibles (separados por coma)</label>
                  <textarea formControlName="colores" class="form-control" placeholder="Azul, Amarillo, Rojo, Verde, ..."></textarea>
                </div>
              </fieldset>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="submit()">Crear</button>
        </div>
      </div>
    </div>
  </div>