import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';
import { NgxNotifierService } from 'ngx-notifier';

declare var $:any;

@Component({
  selector: 'app-modal-entrada-crear',
  templateUrl: './modal-entrada-crear.component.html',
  styleUrls: ['./modal-entrada-crear.component.css']
})
export class ModalEntradaCrearComponent implements OnInit {
  @Input() producto;
  @Output() onCreate = new EventEmitter<any>();
  form: FormGroup;

  constructor( private fb: FirebaseService, private notifier: NgxNotifierService ) { }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges() {
    if ( this.producto && this.producto.id ) {
      //Patch Value
      this.form.patchValue({
        pid: this.producto.id
      });
    }
  }

  initForm() {
    this.form = new FormGroup({
      pid: new FormControl("", Validators.required),
      pedido: new FormControl("", Validators.required),
      fecha_solicitud: new FormControl("", Validators.required),
      fecha_empaque: new FormControl("", Validators.required),
      fecha_entrega: new FormControl(""),
      piezas: new FormControl("", Validators.required),
      comentarios: new FormControl("", [Validators.maxLength(5000)]),
      colores: new FormControl("", [Validators.maxLength(5000)]),
    });
  }

  async submit(){
    this.form.patchValue({
      pid: this.producto.id
    });
    
    if ( this.form.valid ){
      const pallete = this.form.value;
      let existe = false;
      await this.fb.validatePallet(pallete.pedido).then( (callback: boolean) => {
        existe = callback;
      }).catch( e => existe = e )

      if( !existe ) {
        await this.fb.postPallete(pallete).then( (pallete: any) => {
          this.notifier.createToast("Pallete creado correctamente", 'success');
          this.onCreate.emit( pallete );
          this.form.reset();
          $('.modal').modal('hide');
        }).catch( err => console.log('Error: ', err));
      } else{
        this.notifier.createToast("El Pallet " + pallete.pedido + " ya existe", "danger");
      }
      
    } else{
      this.notifier.createToast("Falta información", 'danger');
    }
  }
}
