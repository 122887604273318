
<div style="height: 45px;"></div>

<main class="container">
    <div class="row">
        <div class="col-lg-4">
            <h1 class="display-4">Ordenes</h1>
        </div>
        <div class="col-lg-4 offset-lg-4">
            <div class="row text-secondary" *ngIf="cliente && cliente.id">
                <div class="col-md-3">
                    Cliente:
                </div>
                <div class="col-md-9">
                    {{cliente.nombre}}
                </div>
            </div>

            <div class="row text-info" *ngIf="cliente && cliente.id">
                <div class="col-md-3">
                    Empresa:
                </div>
                <div class="col-md-9">
                    {{cliente.empresa}}
                </div>
            </div>
        </div>
    </div>

    <div style="height: 25px;"></div>

    <div class="row">
        <div class="col-2">
            <button type="button" [routerLink]="['/app', 'clientes']" class="btn btn-outline-secondary btn-block">Regresar</button>
        </div>
        <div class="col-2 offset-8">
            <button type="button" class="btn btn-outline-primary btn-block" data-toggle="modal" data-target="#modal-orden-crear">Nueva</button>
        </div>
    </div>
    
    <div style="height: 25px;"></div>
    <section>

        <div *ngIf="!ordenes.length">
            <p align="center">No hay ordenes en la lista <a href="#" data-toggle="modal" data-target="#modal-orden-crear">agregar una orden</a></p>
        </div>

        <div class="row" *ngIf="ordenes && ordenes.length">
            <div class="col-lg-12">
                <table class="table table-hover dataTables">
                    <thead>
                        <tr>
                            <th>Orden</th>                            
                            <th>Salidas</th>
                            <th>Shipping</th>
                            <th>Diseño</th>
                            <th>SKU</th>
                            <th>Lote</th>
                            <th>Piezas</th>
                            <th>Cajas</th>
                            <th>F. Salida</th>
                            <th>Total</th>                            
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let orden of ordenes">
                            <td>{{orden.id}}</td>
                            <td>
                                {{orden.salidas.length}}
                            </td>
                            <td>
                                <span *ngIf="orden.shipping.proveedor === 'otro'">{{ orden.shipping.otro_proveedor | titlecase }}</span>
                                <span *ngIf="orden.shipping.proveedor !== 'otro'">{{ orden.shipping.proveedor.split('_').join(' ') | titlecase }}</span>
                            </td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            
                            <td>{{orden.fecha}}</td>
                            <td>{{orden.datos_pago.total | currency}}</td>
                            <td>
                                <a href="#" data-toggle="modal" (click)="deleteOrder(orden)">
                                    <i class="material-icons">delete</i>
                                </a>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>

    </section>
</main>

<app-modal-orden-crear (onCreate)="onCreated($event)" [cliente]="cliente"></app-modal-orden-crear>
<app-modal-orden-eliminar (onDelete)="onDeleted($event)" [orden]="orden"></app-modal-orden-eliminar>
<!--
    <app-modal-orden-editar></app-modal-orden-editar>
    <app-modal-orden-eliminar></app-modal-orden-eliminar>
-->