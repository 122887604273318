import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';
import { NgxNotifierService } from 'ngx-notifier';

declare var $:any;

@Component({
  selector: 'app-modal-entrada-eliminar',
  templateUrl: './modal-entrada-eliminar.component.html',
  styleUrls: ['./modal-entrada-eliminar.component.css']
})
export class ModalEntradaEliminarComponent implements OnInit {
  @Input() pallete;
  @Output() onDelete = new EventEmitter<any>();
  form: FormGroup;

  constructor( private fb: FirebaseService, private notifier: NgxNotifierService ) { }

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    if ( this.pallete && this.pallete.id ) {
      //Patch Value
    }
  }

  async submit(){
    await this.fb.deletePallete(this.pallete.id).then( (pallete: any) => {
      this.notifier.createToast("Pallete eliminado correctamente", 'success');
      this.onDelete.emit( pallete );
      $('.modal').modal('hide');
    }).catch( err => console.log('Error: ', err));
  }
}
