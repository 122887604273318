import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

import { NgxNotifierService } from 'ngx-notifier';
import { FirebaseService } from 'src/app/services/firebase.service';
import { Producto } from 'src/app/models/producto.model';

declare var $:any;

@Component({
  selector: 'app-modal-producto-crear',
  templateUrl: './modal-producto-crear.component.html',
  styleUrls: ['./modal-producto-crear.component.css']
})
export class ModalProductoCrearComponent implements OnInit {
  @Input() medidas = [];
  @Output() onCreate = new EventEmitter<any>();
  form: FormGroup;

  constructor( private fb: FirebaseService, private notifier: NgxNotifierService ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      diseno: new FormControl("", Validators.required),
      medidas: new FormControl(""),
      mid: new FormControl("", Validators.required),
      sku: new FormControl("", Validators.required),
      stock: new FormControl("", Validators.required),
      min_cajas: new FormControl("", Validators.required),
      max_cajas: new FormControl("", Validators.required),
      venta: new FormControl(""),
      descripcion: new FormControl("", [Validators.maxLength(1000)]),
      caja: new FormGroup({
        piezas: new FormControl("", Validators.required)
      })
    });
  }

  async submit(){
    if ( this.form.valid ){
      let data: Producto = this.form.value;
      data.venta = data.stock;
      await this.fb.postProducto(data).then( producto => {
        this.notifier.createToast("Nuevo producto agregado correctamente", 'success');
        this.onCreate.emit( producto );
        this.form.reset();
        $('.modal').modal('hide');
      }).catch( err => console.log('Error: ', err));
      
    } else{
      this.notifier.createToast("Falta información", 'error');
    }
  }

}
