import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FirebaseService } from 'src/app/services/firebase.service';
import { NgxNotifierService } from 'ngx-notifier';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Producto } from 'src/app/models/producto.model';

declare var $:any;

@Component({
  selector: 'app-modal-producto-eliminar',
  templateUrl: './modal-producto-eliminar.component.html',
  styleUrls: ['./modal-producto-eliminar.component.css']
})
export class ModalProductoEliminarComponent implements OnInit {
  path: string = "mosaicos";
  @Input() producto;
  @Output() onDelete = new EventEmitter<any>();
  form: FormGroup;

  constructor( private fb: FirebaseService, private notifier: NgxNotifierService ) { }

  ngOnInit(): void {
    
  }

  ngOnChanges() {
    if ( this.producto && this.producto.id ) {
      //Patch Value
      
    }
  }

  async submit(){
    await this.fb.deleteProducto(this.producto.id).then( (producto: any) => {
      this.notifier.createToast("Producto eliminado correctamente", 'success');
      producto.id = this.producto.id;
      this.onDelete.emit( producto );
      
      $('.modal').modal('hide');
    }).catch( err => console.log('Error: ', err)); 
  }

}
