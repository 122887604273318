import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxNotifierService } from 'ngx-notifier';
import { FirebaseService } from 'src/app/services/firebase.service';

declare var $:any;

@Component({
  selector: 'app-modal-cliente-crear',
  templateUrl: './modal-cliente-crear.component.html',
  styleUrls: ['./modal-cliente-crear.component.css']
})
export class ModalClienteCrearComponent implements OnInit {
  form: FormGroup;
  @Output() onCreate = new EventEmitter<any>();

  constructor(private fb: FirebaseService, private notifier: NgxNotifierService) { }

  ngOnInit(): void {
    this.initform();
  }

  initform() {
    this.form = new FormGroup({
      nombre: new FormControl("", Validators.required),
      email: new FormControl("", Validators.required),
      telefono: new FormControl("", Validators.required),
      empresa: new FormControl("", Validators.required),
      
      ciudad: new FormControl("", Validators.required),
      estado: new FormControl("", Validators.required),
      cp: new FormControl("", Validators.required),

      direccion: new FormControl(""),
      rfc: new FormControl("", Validators.maxLength(13)),
      notas: new FormControl("", Validators.maxLength(500)),
    });
  }

  submit() {
    if( this.form.valid ) {
      this.fb.postCliente(this.form.value).then(response => {
        if( response ) {
          this.notifier.createToast("El cliente "+this.form.value.nombre+" se ha creado correctamente", "success", 3000);
          this.onCreate.emit(response);
          this.form.reset();
          $('.modal').modal('hide');
        } else{
          this.notifier.createToast("Lo sentimos ocurrió un problema en crear al cliente", "warning", 3000)    
        }
      }).catch(() => {
        this.notifier.createToast("Lo sentimos ocurrió un problema en crear al cliente", "danger", 3000)    
      });
    } else { 
      this.notifier.createToast("Favor de llenar los datos solicitados.", "danger", 3000)
    }
  }

}
