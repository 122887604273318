import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';
import { NgxNotifierService } from 'ngx-notifier';

declare var $:any;

@Component({
  selector: 'app-modal-entrada-editar',
  templateUrl: './modal-entrada-editar.component.html',
  styleUrls: ['./modal-entrada-editar.component.css']
})
export class ModalEntradaEditarComponent implements OnInit {
  @Input() pallete;
  @Output() onUpdate = new EventEmitter<any>();
  form: FormGroup;

  constructor( private fb: FirebaseService, private notifier: NgxNotifierService ) { }

  ngOnInit(): void {
    this.initForm();
  }

  ngOnChanges() {
    if ( this.pallete && this.pallete.id ) {
      //Patch Value
      this.form.patchValue({
        pedido: this.pallete.pedido,
        fecha_solicitud: this.pallete.fecha_solicitud,
        fecha_empaque: this.pallete.fecha_empaque,
        fecha_entrega: this.pallete.fecha_entrega,
        piezas: this.pallete.piezas,
        comentarios: this.pallete.comentarios,
        colores: this.pallete.colores
      });
    }
  }

  initForm() {
    this.form = new FormGroup({
      pedido: new FormControl("", Validators.required),
      fecha_solicitud: new FormControl("", Validators.required),
      fecha_empaque: new FormControl("", Validators.required),
      fecha_entrega: new FormControl(""),
      piezas: new FormControl("", Validators.required),
      comentarios: new FormControl("", [Validators.maxLength(5000)]),
      colores: new FormControl("", [Validators.maxLength(5000)]),
    });
  }

  async submit(){
    if ( this.form.valid ){
      let pallete = this.form.value;
      pallete.producto = this.pallete.producto;
      await this.fb.putPallete(this.pallete.id, pallete).then( (pallete: any) => {
        this.notifier.createToast("Pallete actualizado correctamente", 'success');
        this.onUpdate.emit( pallete );
        this.form.reset();
        $('.modal').modal('hide');
      }).catch( err => console.log('Error: ', err));
    } else{
      this.notifier.createToast("Falta información", 'danger');
    }
  }
}
