<div style="height: 45px;"></div>

<main class="container">
    <div class="row">
        <div class="col-lg-12">
            <h1 class="display-4">Entradas</h1>
        </div>
    </div>

    <div style="height: 25px;"></div>

    <div class="row">
        <!--<div class="col-2 offset-10">
            <button type="button" class="btn btn-outline-primary btn-block" data-toggle="modal" data-target="#modal-entrada-crear">Nuevo</button>
        </div>-->
        <div class="col-3">
            <button type="button" class="btn btn-outline-secondary btn-block" (click)="exportToExcel('xlsx')">Exportar</button>
        </div>
    </div>
    
    <div style="height: 25px;"></div>
    
    <section>
        <div *ngIf="!palletes.length">
            <p align="center">No hay palletes en la lista <a href="#" [routerLink]="['/app', 'productos']">agregar uno nuevo</a></p>
        </div>

        <div class="row" *ngIf="palletes && palletes.length">
            <div class="col-lg-12">
                <table class="table table-hover dataTables">
                    <thead>
                        <tr>
                            <th>Lote</th>
                            <th>F. Solicitud</th>
                            <th>F. Empaque</th>
                            <th>Piezas</th>
                            <th>Cajas</th>
                            <th>Mosaico</th>
                            <th>Design</th>
                            <th>F. Entrega</th>
                            <th>Colores</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pallet of palletes">
                            <td>{{pallet.pedido}}</td>
                            <td>{{pallet.fecha_solicitud}}</td>
                            <td>{{pallet.fecha_empaque}}</td>
                            <td>
                                <span *ngIf="pallet.piezas_vendidas" class="text-warning">{{pallet.piezas - pallet.piezas_vendidas}}</span>
                                <span *ngIf="!pallet.piezas_vendidas">{{pallet.piezas}}</span>
                            </td>
                            <!--<td></td>-->
                            <td>
                                <span *ngIf="pallet.piezas_vendidas" class="text-warning">{{ pallet.producto.caja.piezas ? ((pallet.piezas-pallet.piezas_vendidas) / pallet.producto.caja.piezas).toFixed(2) : 0 }}</span>
                                <span *ngIf="!pallet.piezas_vendidas">{{ pallet.producto.caja.piezas ? pallet.piezas / pallet.producto.caja.piezas : 0 }}</span>
                                
                            </td>
                            <td>
                                <img [src]="pallet.producto.img" class="icon" />
                            </td>
                            <td>
                                <a href="#" data-toggle="modal" data-target="#modal-producto-consulta" (click)="select(pallet)">{{pallet.producto.diseno}}</a>
                            </td>
                            <td>{{pallet.fecha_entrega}}</td>
                            <td>
                                <div [innerHTML]="getTagColores(pallet.colores)"></div>
                            </td>
                            <td>
                                <a class="nav-link dropdown-toggle btn btn-danger btn-sm" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <i class="material-icons">settings</i>
                                </a>
                                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#modal-entrada-editar" (click)="select(pallet)">
                                        <i class="material-icons">edit</i> Editar
                                    </a>
                                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#modal-entrada-eliminar" (click)="select(pallet)">
                                        <i class="material-icons">delete</i> Eliminar 
                                    </a> 
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div class="row" *ngIf="palletes && palletes.length" style="visibility: hidden; height: 0; display: none;">
            <div class="col-lg-12">
                <table id="table_pallets_export" class="table table-hover">
                    <thead>
                        <tr>
                            <th>Lote</th>
                            <th>F. Solicitud</th>
                            <th>F. Empaque</th>
                            <th>Piezas</th>
                            <th>Cajas</th>
                            <th>Design</th>
                            <th>F. Entrega</th>
                            <th>Colores</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let pallet of palletes">
                            <td>{{pallet.pedido}}</td>
                            <td>{{pallet.fecha_solicitud}}</td>
                            <td>{{pallet.fecha_empaque}}</td>
                            <td>
                                <span *ngIf="pallet.piezas_vendidas" class="text-warning">{{pallet.piezas - pallet.piezas_vendidas}}</span>
                                <span *ngIf="!pallet.piezas_vendidas">{{pallet.piezas}}</span>
                            </td>
                            <!--<td></td>-->
                            <td>
                                <span *ngIf="pallet.piezas_vendidas" class="text-warning">{{ pallet.producto.caja.piezas ? ((pallet.piezas-pallet.piezas_vendidas) / pallet.producto.caja.piezas).toFixed(2) : 0 }}</span>
                                <span *ngIf="!pallet.piezas_vendidas">{{ pallet.producto.caja.piezas ? pallet.piezas / pallet.producto.caja.piezas : 0 }}</span>
                                
                            </td>
                            <td>
                                <a href="#" data-toggle="modal" data-target="#modal-producto-consulta" (click)="select(pallet)">{{pallet.producto.diseno}}</a>
                            </td>
                            <td>{{pallet.fecha_entrega}}</td>
                            <td>
                                <div [innerHTML]="getTagColores(pallet.colores)"></div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </section>
</main>

<app-modal-producto-consulta [pallete]="pallete"></app-modal-producto-consulta>
<app-modal-entrada-editar [pallete]="pallete" (onUpdate)="onUpdated($event)"></app-modal-entrada-editar>
<app-modal-entrada-eliminar [pallete]="pallete" (onDelete)="onDeleted($event)"></app-modal-entrada-eliminar>