import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxNotifierService } from 'ngx-notifier';
import { FirebaseService } from 'src/app/services/firebase.service';
import { UserService } from 'src/app/services/user.service';

declare var $:any;

@Component({
  selector: 'app-modal-orden-crear',
  templateUrl: './modal-orden-crear.component.html',
  styleUrls: ['./modal-orden-crear.component.css']
})
export class ModalOrdenCrearComponent implements OnInit, OnChanges {
  lote: string;
  lotes: Array<any> = [];
  form: FormGroup;
  searchForm: FormGroup;
  subtotal:any = 0;
  order: Boolean = false;
  salidas = [];
  keywordSearch: string = "";
  productos: Array<any> = [];
  lista = [];

  proveedores = [
    'paquete_express',
    'estafeta',
    'dhl',
    'fedex',
    'ups',
    //'penske',
    //'ryder',
    //'globalpaq',
    'otro'
  ];

  pallets: Array<any> = [];

  @Input() cliente;
  @Output() onCreate = new EventEmitter<any>();

  metodos_pago: Array<string> = [
    'transferencia',
    'tarjeta_debito',
    'tarjeta_credito',
    'paypal',
    'deposito',
    'efectivo'
  ];
  
  constructor( private fb: FirebaseService, private notifier: NgxNotifierService ) { }

  async ngOnInit() {
    this.initform();

    await this.fb.getPalletes().then( (callback: Array<any>) => {
      this.lotes = callback;
    });

    await this.fb.getProductos().then( (callback: Array<any>) => {
      this.productos = callback.map( p => {
        p.lotes = this.lotes.filter( l => l.pid === p.id );
        return p;
      });
      //console.log('Productos ', this.productos);
    });

    
  }

  ngOnChanges() {
    if(this.cliente && this.cliente.id) {
      //console.log('entra el cliente', this.cliente);
      this.form.patchValue({
        cliente_id: this.cliente.id
      })
    }
  }

  initform() {
    const date = new Date();

    this.form = new FormGroup({
      cliente_id: new FormControl("", Validators.required),
      id: new FormControl("", Validators.required),
      fecha: new FormControl(date.toLocaleDateString(), Validators.required),
      datos_pago: new FormGroup({
        usd: new FormControl("", Validators.required),
        total: new FormControl("", Validators.required),
        metodo_pago: new FormControl("", Validators.required),
      }),
      shipping: new FormGroup({
        proveedor: new FormControl("", Validators.required),
        costo: new FormControl("", Validators.required),
        guia: new FormControl(""),
        fecha_entrega: new FormControl(date.toLocaleDateString())
      }),
      comentarios: new FormControl("")
    });

    this.searchForm = new FormGroup({
      keywordSearch: new FormControl("", Validators.required)
    });
  }

  async submit() {
    let ordenCreada = false;

    if(isNaN(this.subtotal)){
      this.notifier.createToast("Ingresa un subtotal numérico válido", "danger");
      return 0; 
    }

    if( this.form.valid && !this.order ) {
      //console.log("Form: ", this.form.value);
      this.order = true;
    }

    if( this.form.valid && this.salidas.length > 0) {
      //Verifica si se seleccionó otro proveedor
      let _data = {...this.form.value, salidas: this.salidas.length};
      const element: any = document.getElementById('otro_proveedor');
      _data.otro_proveedor = this.form.value.shipping === 'otro' ? element.value : null;
      const newOrder: any = await this.fb.postOrden(_data);/*.then(id => {
        console.log('Order Id', id);
      }).catch(() => this.notifier.createToast("Ocurrió un error de conexión.", "danger"));*/

      //console.log('Order', newOrder);

      if( newOrder ){
          ordenCreada = true;
          //  Crear las siguientes salidas.
          for(let i = 0; i < this.salidas.length; i++) {
            let salida = { ...this.salidas[i], orden_id: newOrder, status: 'pendiente', comentarios: ""};
            await this.fb.postSalida(salida).then(id => this.salidas[i].id = id).catch();

            //Agregarle a cada Pallet en el campo de piezas_vendidas + ${las piezas vendidas en esta orden}
            let pallet: any = await this.fb.getPallet(this.salidas[i].entrada_id);
            let sold = pallet && pallet.piezas_vendidas ? pallet.piezas_vendidas : 0;
            await this.fb.putPallete(this.salidas[i].entrada_id, { ...pallet, piezas_vendidas: sold + this.salidas[i].cantidad});
          }        

        this.onCreate.emit({...this.form.value, salidas: this.salidas});
        this.form.reset();

        const date = new Date();

        //Reestablecer los valores.
        this.form.patchValue({
          fecha: date.toLocaleDateString(),
          shipping: {
            fecha_entrega: date.toLocaleDateString()
          }
        });

        this.order = false;
        $('.modal').modal('hide');
      }
    } else{
      this.notifier.createToast("Selecciona al menos un producto a agregar en la orden", "danger");
    }
  }

  calcularTotal() {
    if(isNaN(this.subtotal)){
      this.notifier.createToast("Ingresa un valor numérico", "info");
      return 0; 
    }

    this.form.patchValue({
      datos_pago: {
        iva: Number(this.subtotal * 0.16),
        total: Number(this.subtotal) + Number(this.subtotal * 0.16)
      }
    });
  }

  buscarProductos(){
    //console.log(`Buscando ${this.keywordSearch}`);
    this.lista = this.productos.filter( p => p.diseno.indexOf(this.keywordSearch.toUpperCase()) != -1);

    //console.log('Lista', this.lista);
  }

  addProduct( p ) {
    /*const cantidad = $('#producto'+p.id).val();
    let existe = false;
    for(const a of this.salidas) {
      if( a.producto.id === p.id ) {
        existe = true;
      }
    }

    if(existe) {
      this.notifier.createToast("Este producto ya se encuentra en la lista de la orden.", "danger");
      return 0; 
    }

    if(isNaN(cantidad)){
      this.notifier.createToast("Ingresa un valor numérico", "danger");
      return 0; 
    }*/

    const salida = {
      producto: p,
      cantidad: 0,
      entrada_id: "",
      stock: 0
    };

    this.salidas.push(salida);
  }

  cuentaPiezas( lotes: any ) {
    let piezas = 0;

    if( lotes ) {
      for(const a of lotes) {
        let vendidas = a.piezas_vendidas ? a.piezas_vendidas : 0;
        piezas = piezas + parseInt(a.piezas) - vendidas;
      }
    }

    return piezas;
  }

  removeProduct(id: string) {
    this.salidas = this.salidas.filter(s => s.producto.id != id );
  }

  getStock( pid: string ) {
    let entradas = this.salidas.map( (s: any) => {
      let entry: any;
      if( s.producto.id === pid ){  
        entry = s.producto.lotes.filter( (e: any) => e.id === s.entrada_id )[0];
      }
      return entry;
    });

    //console.log('Entrada: ', entradas);
    
    for( let i = 0; i < this.salidas.length; i++ ) {
      if( this.salidas[i].producto.id === pid ) {
        //console.log(`Vendidas ${entradas[i].piezas_vendidas} para piezas ${entradas[i].piezas}`);
        this.salidas[i].stock = parseInt(entradas[i].piezas) - entradas[i].piezas_vendidas;
        this.salidas[i].vendidas = entradas[i].piezas_vendidas ? entradas[i].piezas_vendidas : 0;
      }
    }

  }

  verifyProvider() {
    //Non Sense
  }
}
