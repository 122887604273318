import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { FirebaseService } from 'src/app/services/firebase.service';
import { NgxNotifierService } from 'ngx-notifier';

declare var $:any;

@Component({
  selector: 'app-modal-medida-crear',
  templateUrl: './modal-medida-crear.component.html',
  styleUrls: ['./modal-medida-crear.component.css']
})
export class ModalMedidaCrearComponent implements OnInit {
  @Input() medida;
  @Output() onCreate = new EventEmitter<any>();
  form: FormGroup;

  constructor( private fb: FirebaseService, private notifier: NgxNotifierService ) { }

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.form = new FormGroup({
      nombre: new FormControl("", Validators.required),
      descripcion: new FormControl(""),
      pies: new FormControl("", Validators.required),
      m2: new FormControl("", Validators.required)
    });
  }

  async submit(){
    if ( this.form.valid ){
      let data: any = this.form.value;
      await this.fb.postMedida(data).then( producto => {
        this.notifier.createToast("Nueva medida agregado correctamente", 'success');
        this.onCreate.emit( producto );
        this.form.reset();
        $('.modal').modal('hide');
      }).catch( err => console.log('Error: ', err));
      
    } else{
      this.notifier.createToast("Falta información", 'error');
    }
  }

}
