<!-- Modal -->
<div class="modal fade" id="modal-medida-crear" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="staticBackdropLabel">Nueva Medida</h5>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-secondary">
            <small>
              Esta medida se multiplica por el número de cajas para obtener la cantidad de cajas en pies cúbicos y en m2.
            </small>
          </p>
          <form [formGroup]="form" (ngSubmit)="submit()">
              <fieldset>
                <div class="form-group">
                    <input type="text" formControlName="nombre" class="form-control" placeholder="Medida o Nombre de la medida*" />
                </div>
                <div class="form-group">
                    <input type="digits" formControlName="pies" class="form-control" placeholder="Conv. en Pies*" />
                </div>
                <div class="form-group">
                    <input type="digits" formControlName="m2" class="form-control" placeholder="Conv. en m2*" />
                </div>
                <div class="form-group">
                    <textarea formControlName="descripcion" class="form-control" placeholder="Descripción para esta medida"></textarea>
                </div>
              </fieldset>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancelar</button>
          <button type="button" class="btn btn-primary" (click)="submit()">Crear</button>
        </div>
      </div>
    </div>
  </div>