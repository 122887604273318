import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Cliente } from 'src/app/models/cliente.model';
import { FirebaseService } from 'src/app/services/firebase.service';
import { UserService } from 'src/app/services/user.service';

declare var $:any;
@Component({
  selector: 'app-ordenes',
  templateUrl: './ordenes.component.html',
  styleUrls: ['./ordenes.component.css']
})
export class OrdenesComponent implements OnInit {
  ordenes: Array<any> = [];
  cliente: Cliente;
  orden: any;

  constructor( private fb: FirebaseService, private routes: ActivatedRoute, private us: UserService ) { }

  ngOnInit(): void {
    this.routes.params.subscribe((params: Params) => {
      if( params.id ) {
        this.fb.getClientes(params.id).then( (cliente: Cliente) => {
          this.cliente = cliente;
          this.cargarOrdenes(this.cliente.id);  //Cargar Ordenes
        }).catch(() => {});
      }
    })
  }

  async cargarOrdenes(id?:string) {
    const ordenes: any = await this.fb.getOrdenes(id);
    this.ordenes = ordenes ? ordenes : [];
    this.us.setDataTables();

    await this.cargarSalidasPorOrden();
  }

  onCreated(event) {
    if(event) {
      //console.log('Event: ', event);
      this.ordenes.push(event);
      this.us.setDataTables();
    }
  }

  async cargarSalidasPorOrden() {
    for(let i = 0; i < this.ordenes.length; i++ ) {
      await this.fb.getSalidas(this.ordenes[i].id).then(salidas => {
        this.ordenes[i].salidas = salidas;
      }).catch(e => console.log('Error: ', e));
    }
  }

  deleteOrder(orden) {
    this.orden = orden;
    $('#modal-orden-eliminar').modal('show');
  }

  onDeleted(event) {
    if(event) {
      //console.log('Event: ', event);
      this.ordenes = this.ordenes.filter( ord => ord.id !== event.id);
      this.us.setDataTables();
      $('#modal-orden-eliminar').modal('hide');
    }
  }

}
