<!--<form>
    <input id="file" type="file" class="form-control" />
    
</form>-->

<input id="file" style="visibility: hidden;" type="file" (change)="fileChangeEvent($event)" *ngIf="!onCropping" />
<div *ngIf="!onCropping">
    <p align="center">
        <img [src]="initialImage" (click)="select()" class="img-fluid" />
    </p>
</div>

<image-cropper
    [imageChangedEvent]="imageChangedEvent"
    [maintainAspectRatio]="true"
    [aspectRatio]="4 / 4"
    format="jpeg"
    (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()"
    (cropperReady)="cropperReady()"
    (loadImageFailed)="loadImageFailed()"
></image-cropper>

<div class="row" *ngIf="onCropping">
    <div class="col">
        <button type="button" class="btn btn-outline-secondary btn-block" (click)="cancelar()">Cancelar</button>
    </div>
    <div class="col">
        <button type="button" class="btn btn-outline-primary btn-block" (click)="upload()">Recortar</button>
    </div>
</div>